<template functional>
  <div>
    <h1>Политика за връщане</h1>
    <p>Последна промяна 2020-10-24</p>

    <h1>Определения и ключови термини</h1>
    <p>За да ви помогнем да разберете нещата възможно най-ясно в тази Политика за връщане на средства, всеки път, когато някой от тези условия са
      посочени, са строго дефинирани като:</p>
    <ul>
      <li>„Бисквитка“: малко количество данни, генерирани от уебсайт и запазени от вашия уеб браузър. Използва се, за да
        идентифицираме браузъра ви, осигурим анализ, запомняме информация за вас, като вашия език и
        информация за предпочитания и/или вход.
      </li>
      <li>Фирма: когато тази политика споменава „Компания“, „ние“, „ние“ или „нашата“, това се отнася до PriceX.app, който е
        отговорен за вашата информация съгласно настоящите Общи условия.
      </li>
      <li>Клиент: отнася се до компанията, организацията или лицето, което се регистрира за използване на услугата PriceX.app,
        за да управлява взаимоотношенията с нашите потребители или търговци на услуги.
      </li>
      <li>Устройство: всяко свързано с интернет устройство, като телефон, таблет, компютър или друго устройство, което може да бъде
        използвани за посещение на PriceX.app и използване на услугите.
      </li>
      <li>Услуга: отнася се до услугата, предоставяна от PriceX.app, както е описано в относителните условия (ако е налично)
        и на тази платформа.
      </li>
      <li>Уебсайт: PriceX.app е сайт, който може да бъде достъпен чрез този URL: https://pricex.app</li>
      <li>Вие: физическо или юридическо лице, което е регистрирано в PriceX.app, за да използва Услугите.</li>
    </ul>

    <h1>Политика за връщане</h1>
    <p>Благодаря че използвате услугите на PriceX.app. Оценяваме факта, че харесвате нашите услуги. Ние също искаме
      за да сте сигурни, че имате приятно преживяване, докато проучвате, оценявате и използвате нашите услуги.</p>
    <p>Както при всяко изживяване при пазаруване, и тук има условия, които се прилагат за транзакции в PriceX.app. Ще бъдем
      толкова кратки, колкото позволяват нашите адвокати. Основното нещо, което трябва да запомните е, че като направите поръчка или
      закупувайки в PriceX.app, вие се съгласявате с условията, посочени по-долу, заедно с Политиката.</p>

    <h1>Възстановяване на суми</h1>
    <p>Всеки от нашите абонаментни планове има 14 дневен период при първи абонамент в който не се начисляват суми върху вашата кредитна карта.</p>
    <p>Кенселиране на услугата в този период ще пракрати всякакво таксуване върху вашата карта</p>
    <p>При кенселирането на абонамента след пробния период се възстановява остатъчната сума</p>
    <p>PriceX.app си запазва правото да отказва абонамент на потребители, които според нас злоупотребяват със системата за абонамент.</p>
    <p></p>
    <p>Като използвате нашия уебсайт, регистрирате акаунт или правите покупка, вие се съгласявате с нашето връщане и възстановяване
      Политика и се съгласете с нейните условия.</p>

    <h1>Промени в нашата политика за връщане и възстановяване</h1>
    <p>В случай, че трябва да актуализираме, поправим или направим някакви промени в този документ, така че да отразяват точно нашата Услуга и
      политики ще ви уведомим своевременно. Освен ако законът не изисква друго, тези промени ще бъдат публикувани на видно място тук. След това, ако продължите
      за да използвате Услугата, ще бъдете обвързани от актуализираната Политика за връщане и възстановяване. Ако не искате да се съгласите с това
      или всяка актуализирана Политика за връщане и възстановяване на суми, можете да изтриете акаунта си.</p>

    <p>Ако по някаква причина не сте напълно доволни от каквито и да е стоки или услуги, които предоставяме, не се колебайте
      свържете се с нас и ние ще обсъдим всеки от проблемите, които преживявате с нашия продукт.</p>
  </div>
</template>

<script>
export default {
  name: "BgReturnPolicy"
}
</script>