<template>
  <b-container class="mt-3 pb-2">
    <b-card>
      <b-tabs v-model="termsPage" :vertical="!isMobile" pills>
        <b-tab :title="$t('terms.terms_and_conditions')" lazy>
          <bg-terms-and-conditions v-if="locale === 'bg'"></bg-terms-and-conditions>
          <en-terms-and-conditions v-else></en-terms-and-conditions>
          <terms-contacts-element></terms-contacts-element>
        </b-tab>
        <b-tab :title="$t('terms.privacy_policy')" lazy>
          <bg-privacy-policy v-if="locale === 'bg'"></bg-privacy-policy>
          <en-privacy-policy v-else></en-privacy-policy>
          <terms-contacts-element></terms-contacts-element>
        </b-tab>
        <b-tab :title="$t('terms.return_and_refund_policy')" lazy>
          <bg-return-policy v-if="locale === 'bg'"></bg-return-policy>
          <en-return-policy v-else></en-return-policy>
          <terms-contacts-element></terms-contacts-element>
        </b-tab>
        <b-tab :title="$t('terms.cookies_policy')" lazy>
          <bg-cookies-policy v-if="locale === 'bg'"></bg-cookies-policy>
          <en-cookies-policy v-else></en-cookies-policy>
          <terms-contacts-element></terms-contacts-element>
        </b-tab>
        <b-tab :title="$t('terms.disclaimer')" lazy>
          <bg-disclaimer v-if="locale === 'bg'"></bg-disclaimer>
          <en-disclaimer v-else></en-disclaimer>
          <terms-contacts-element></terms-contacts-element>
        </b-tab>
        <b-tab :title="$t('terms.enduser_license_agreement')" lazy>
          <bg-eula v-if="locale === 'bg'"></bg-eula>
          <en-eula v-else></en-eula>
          <terms-contacts-element></terms-contacts-element>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-container>
</template>

<script>
import {BTabs, BTab, BCard, BContainer} from "bootstrap-vue";
import _ from "lodash"
import BgTermsAndConditions from "@/views/pages/terms/bg/BgTermsAndConditions";
import BgPrivacyPolicy from "@/views/pages/terms/bg/BgPrivacyPolicy";
import BgReturnPolicy from "@/views/pages/terms/bg/BgReturnPolicy";
import BgCookiesPolicy from "@/views/pages/terms/bg/BgCookiesPolicy";
import BgDisclaimer from "@/views/pages/terms/bg/BgDisclaimer";
import BgEula from "@/views/pages/terms/bg/BgEula";
import EnTermsAndConditions from "@/views/pages/terms/en/EnTermsAndConditions";
import EnPrivacyPolicy from "@/views/pages/terms/en/EnPrivacyPolicy";
import EnReturnPolicy from "@/views/pages/terms/en/EnReturnPolicy";
import EnCookiesPolicy from "@/views/pages/terms/en/EnCookiesPolicy";
import EnDisclaimer from "@/views/pages/terms/en/EnDisclaimer";
import EnEula from "@/views/pages/terms/en/EnEula";
import i18n from '@/libs/i18n'
import TermsContactsElement from "@/views/pages/terms/terms-contacts-element";

export default {
  components: {
    TermsContactsElement,
    EnEula,
    EnDisclaimer,
    EnCookiesPolicy,
    EnReturnPolicy,
    EnPrivacyPolicy,
    EnTermsAndConditions,
    BgEula,
    BgDisclaimer,
    BgCookiesPolicy,
    BgReturnPolicy,
    BgPrivacyPolicy,
    BgTermsAndConditions,
    BTabs, BTab, BCard, BContainer
  },
  name: "terms",
  metaInfo(){
    return {
      title: this.pageTitles[this.termsPage]
    }
  },
  data() {
    return {
      termsPage: 0,
      pageToIndex: {
        'terms-and-conditions': 0,
        'privacy-policy': 1,
        'return-policy': 2,
        'cookie-policy': 3,
        'disclaimer': 4,
        'eula': 5
      },
      pageTitles: {
        0: this.$t('terms.terms_and_conditions'),
        1: this.$t('terms.privacy_policy'),
        2: this.$t('terms.return_and_refund_policy'),
        3: this.$t('terms.cookies_policy'),
        4: this.$t('terms.disclaimer'),
        5: this.$t('terms.enduser_license_agreement')
      }
    }
  },
  created() {
    this.termsPage = _.get(this.pageToIndex, this.$route.params.page, 0);
  },
  computed: {
    locale() {
      return i18n.locale
    },
    isMobile(){
      return ['xs', 'sm', 'md'].indexOf(this.$store.getters["app/currentBreakPoint"]) > -1
    }
  },
  watch: {
    termsPage(val) {
      let key = _.findKey(this.pageToIndex, item => item === val);
      if (this.$route.params.page !== key) {
        this.$router.push({name: 'terms', params: {page: key}})
      }
    },
    $route(to) {
      if (_.get(this.pageToIndex, to.params.page, 0) !== to.params.page) {
        this.termsPage = _.get(this.pageToIndex, to.params.page, 0)
      }
    }
  }
}
</script>