<template>
  <div>
    <h1>{{ $t('terms.contact_us') }}</h1>
    <p>{{ $t('terms.dont_hesitate_to_contact_us_if_you_have_any_questi') }}</p>
    <ul>
      <li>{{ $t('terms.via_email') }} <a href="mailto:support@pricex.app">support@pricex.app</a></li>
      <li>{{ $t('terms.via_this_link') }} <router-link :to="{name: 'contacts'}"  custom v-slot="{ navigate }">
        <span @click="navigate" @keypress.enter="navigate" role="link" class="cursor-pointer">{{ $t('buttons.contacts') }}</span>
      </router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "terms-contacts-element"
}
</script>