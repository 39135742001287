<template functional>
  <div>
    <h1>Лицензионно споразумение с краен потребител</h1>
    <p>Последна промяна 2020-10-24</p>

    <p>PriceX.app ви предоставя достъп до https://pricex.app („Уебсайтът“) и ви кани да закупите
      услуги, предлагани тук.</p>

    <h1>Определения и ключови термини</h1>
    <p>За да ви помогнем да разберете нещата възможно най-ясно в тази Политика за поверителност, всеки път, когато някой от тези условия са
      посочени, са строго дефинирани като:</p>
    <ul>
      <li>„Бисквитка“: малко количество данни, генерирани от уебсайт и запазени от вашия уеб браузър. Използва се, за да
        идентифицираме браузъра ви, осигурим анализ, запомняме информация за вас, като вашия език и
        информация за предпочитания и/или вход.
      </li>
      <li>Фирма: когато тази политика споменава „Компания“, „ние“, „ние“ или „нашата“, това се отнася до PriceX.app, който е
        отговорен за вашата информация съгласно настоящите Общи условия.
      </li>
      <li>Държава: където са базирани PriceX.app или собствениците / основателите на PriceX.app, в този случай е България</li>
      <li>Услуга: отнася се до услугата, предоставяна от PriceX.app, както е описано в относителните условия (ако е налично)
        и на тази платформа.
      </li>
      <li>Услуга на трета страна: отнася се до рекламодатели, спонсори на конкурси, промоционални и маркетингови партньори и
        други, които предоставят нашето съдържание или чиито продукти или услуги смятаме, че могат да ви заинтересуват.
      </li>
      <li>Уебсайт: PriceX.app е сайт, който може да бъде достъпен чрез този URL: https://pricex.app</li>
      <li>Вие: физическо или юридическо лице, което е регистрирано в PriceX.app, за да използва Услугите.</li>
    </ul>


    <h1>Въведение</h1>
    <p>Това лицензионно споразумение за краен потребител („Споразумението“) е обвързващо споразумение между вас („Краен потребител“, „вие“ или „вашето“)
      и („Компания“, „ние“ или „нашата“). Настоящото споразумение урежда отношенията между вас и нас и вашето използване
      на компанията PriceX.app. По време на настоящото споразумение крайният потребител и компания могат да бъдат наричани „Страна“
      или заедно, „Страните“.</p>
    <p>Ако използвате уебсайта от името на вашия работодател или друг субект („Организация“), в чиято полза вие
      да използвате уебсайта или който притежава или контролира по друг начин средствата, чрез които използвате или осъществявате достъп до уебсайта,
      тогава термините „Краен потребител“, „вие“ и „вашият“ се прилагат колективно за вас като физическо лице и за
      Организация. Ако използвате или закупите лиценз или към уебсайта от името на Организация, вие с това
      потвърждавате, давате гаранция и завет, че имате право да 1) закупите лиценз за уебсайта от името
      на Организацията; 2) обвързва Организацията с условията на настоящото споразумение.</p>
    <p>Чрез изтегляне, инсталиране, достъп или използване на уебсайта вие: (а) потвърждавате, че разполагате с всяко необходимо
      разрешение за достъп и използване на уебсайта; (б) ако използвате уебсайта съгласно
      лиценз, закупен от организация, че сте упълномощени от тази организация за достъп и използване на
      уебсайта (в) потвърждавате, че сте прочели и разбирате това споразумение; (г) представяте, че сте от
      здрав разум и пълнолетна възраст (на 18 или повече години) за сключване на обвързващо споразумение; и (д) приемате и
      се съгласявате да бъдете правно обвързани от условията на това споразумение.</p>
    <p>Ако не сте съгласни с тези условия, не изтегляйте, не инсталирайте, не използвайте и не използвайте софтуера. ако вече сте го направили
      изтегли софтуера, изтрийте го от вашето компютърно устройство</p>
    <p>Приложението е лицензирано, а не продавано от PriceX.app на вас за използване стриктно в съответствие с условията на това
      Споразумение.</p>

    <h1>Разрешително</h1>
    <p>При спазване на условията на настоящото споразумение и ако е приложимо, условията, предвидени в лицензионното споразумение,
      PriceX.app ви предоставя ограничен, неизключителен, безсрочен, отменяем и непрехвърлим лиценз за:</p>
    <p>(а) изтеглете, инсталирайте и използвайте Софтуера на едно (1) изчислително устройство за всеки потребителски лиценз, който имате
      закупени и отпуснати. Ако имате няколко компютърни устройства, в които искате да използвате Софтуера, вие
      се съгласявате да придобиете лиценз за броя на устройствата, които възнамерявате да използвате;</p>
    <p>(б) достъп, преглед и използване на такова изчислително устройство на предоставените от крайния потребител материали, предоставени в или по друг начин
      достъпни чрез Софтуера, стриктно в съответствие с настоящото споразумение, както и всички други условия
      приложимо за такива предоставени на крайния потребител материали;</p>
    <p>(в) да инсталирате и използвате пробната версия на Софтуера на произволен брой изчислителни устройства за пробен период от
      петнадесет (15) уникални дни след инсталирането.</p>

    <h1>Ограничения</h1>
    <p>Вие се съгласявате да не го правите и няма да разрешите на другите да:</p>
    <ul>
      <li>Лицензирате, продавайте, наемате, възлагате, разпространявате, предавате, хоствате, възлагате, разкривате или по друг начин
        експлоатирате с търговска цел приложението или го направете достъпно за трета страна.
      </li>
      <li>Модифицирате, направете производни произведения на, разглобявате, дешифрирате или реверсирате някоя част от
        Приложение.
      </li>
      <li>Премахнете, променяте или скривате всяко собственическо известие (включително всяко известие за авторски права или търговска марка) на
        PriceX.app или неговите филиали, партньори, доставчици или лицензодатели на Приложението.
      </li>
    </ul>

    <h1>Интелектуална собственост</h1>
    <p>Всички права на интелектуална собственост, включително авторски права, патенти, разкриване на патенти и изобретения (дали
      патентован или не), търговски марки служебни марки, търговски тайни, ноу-хау и друга поверителна информация,
      търговски имена, лога, корпоративни имена и имена на домейни, заедно с цялата всичко свързано с тях,
      производни произведения и всички други права (заедно, „Права на интелектуална собственост“), които са част от
      Софтуерът, който иначе е собственост на PriceX.app, винаги остава изключителната собственост на PriceX.app (или на
      неговите доставчици или лицензодатели, ако и когато е приложимо). Нищо в настоящото споразумение не ви предоставя (или която и да е организация)
      лиценз за PriceX.app Права на интелектуална собственост.</p>
    <p>Вие се съгласявате, че настоящото споразумение предоставя ограничен лиценз за използване на PriceX.app Права на интелектуална собственост,
      единствено като част от Софтуера (а не независимо от него) и само за действителния срок на лиценза
      предоставени на вас по-долу. Съответно, използването на което и да е от правата на интелектуална собственост на PriceX.app
      независимо от Софтуера или извън обхвата на настоящото споразумение, ще се счита за нарушение на
      PriceX.app Права на интелектуална собственост. Това обаче не ограничава претенциите на PriceX.app за a
      нарушение на договора в случай, че нарушите условие или условие на настоящото споразумение. Ще използвате най-високата
      стандартни грижи за защита на целия Софтуер (включително всички негови копия) от нарушаване, присвояване,
      кражба, злоупотреба или неоторизиран достъп. С изключение на изрично предоставеното в настоящото споразумение, PriceX.app запазва и
      ще запази всички права, заглавие и интерес към Софтуера, включително всички авторски права и обект на авторски права,
      търговски марки и обект на запазена марка, патенти и патентован предмет, търговски тайни и
      други права на интелектуална собственост, регистрирани, нерегистрирани, предоставени, заявени или и двете съществуващи сега или
      които могат да бъдат създадени във връзка с тях.</p>
    <p>Вие (или Организацията, ако и както е приложимо) ще запазите собствеността върху всички права на интелектуална собственост в и
      към работните продукти, които създавате чрез или с помощта на Софтуера.</p>

    <h1>Вашите предложения</h1>
    <p>Всякакви отзиви, коментари, идеи, подобрения или предложения (заедно „Предложения“), предоставени от вас
      PriceX.app по отношение на Приложението остава единствената и изключителна собственост на PriceX.app.</p>
    <p>PriceX.app ще бъде свободен да използва, копира, модифицира, публикува или разпространява предложенията за всякакви цели и в
      по какъвто и да е начин без никакъв кредит или каквато и да е компенсация за вас.</p>

    <h1>Модификации на приложението</h1>
    <p>PriceX.app си запазва правото да модифицира, преустановява или прекратява, временно или окончателно, Приложението или
      всяка услуга, към която се свързва, със или без предизвестие и без отговорност към вас.</p>

    <h1>Актуализации на приложението</h1>
    <p>PriceX.app може от време на време да предоставя подобрения или подобрения на функциите / функционалността на
      Приложение, което може да включва корекции, корекции на грешки, актуализации, надстройки и други модификации („Актуализации“). </p>
    <p>Актуализациите могат да модифицират или изтрият определени функции и / или функционалности на Приложението. Вие се съгласявате с това.
      PriceX.app няма задължение (i) да предоставя каквито и да е Актуализации или (ii) да продължава да предоставя или разрешава някакви конкретни
      функции и / или функционалности на Приложението за вас.</p>
    <p>Освен това се съгласявате, че всички Актуализации (i) ще се считат за неразделна част от Приложението и (ii)
      при спазване на условията на настоящото споразумение. </p>
    <h1>Услуги от трети страни</h1>
    <p>Приложението може да показва, включва или предоставя на разположение съдържание на трети страни (включително данни, информация,
      приложения и други продукти и услуги) или да предоставят връзки към уебсайтове или услуги на трети страни („Трета страна
      Услуги ").</p>
    <p>Вие признавате и се съгласявате, че PriceX.app не носи отговорност за услуги на трети страни, включително техните
      точност, пълнота, навременност, валидност, спазване на авторските права, законност, благоприличие, качество или каквото и да било друго
      в този аспект. PriceX.app не поема и няма да носи никаква отговорност към вас или каквато и да било
      друго лице или субект за услуги на трети страни.</p>
    <p>Услугите на трети страни и връзките към тях се предоставят единствено за ваше удобство и вие имате достъп до тях и ги използвате
      изцяло на ваш риск и при спазване на условията на такива трети страни.</p>
    <h1>Срок и прекратяване</h1>
    <p>Настоящото споразумение остава в сила, докато не бъде прекратено от вас или PriceX.app. </p>
    <p>PriceX.app може, по свое усмотрение, по всяко време и по каквато и да е причина, да спре или прекрати настоящото споразумение
      със или без предварително уведомление.</p>
    <p>Настоящото споразумение ще прекрати незабавно, без предварително известие от PriceX.app, в случай че не успеете
      спазите всяка разпоредба на настоящото споразумение. Можете също така да прекратите настоящото споразумение, като изтриете приложението
      и всичките му копия от вашия компютър.</p>
    <p>При прекратяване на настоящото споразумение вие ще прекратите всякакво използване на Приложението и ще изтриете всички копия на
      Приложение от вашия компютър.</p>
    <p>Прекратяването на настоящото споразумение няма да ограничи нито едно от правата или средствата за правна защита на PriceX.app. По закон или в капитала в
      случай на нарушение от ваша страна (по време на срока на настоящото споразумение) на някое от вашите задължения по настоящето
      Споразумение.</p>

    <h1>Обезщетение</h1>
    <p>Вие се съгласявате да обезщетите, защитите и пазите безвредна PriceX.app и нейните служители, директори, служители, агенти,
      филиали, наследници от и срещу всякакви загуби, щети, задължения, недостатъци,
      искове, действия, съдебни решения, лихви, награди, неустойки, глоби, разходи или разноски от каквото и да било
      вид, включително разумни адвокатски хонорари, произтичащи от или свързани с: i) вашето използване или злоупотреба със Софтуера;
      ii) неспазването на който и да е приложим закон, регламент или правителствена директива; iii) Вашето нарушение на
      настоящото споразумение; или iv) вашето споразумение или връзка с Организация (ако е приложимо) или трета страна.
      Освен това се съгласявате, че PriceX.app не поема отговорност за информацията или съдържанието, които изпращате или
      направете достъпни чрез този Софтуер или съдържанието, което Ви се предоставя от трети страни.</p>

    <h1>Без гаранции</h1>
    <p>Приложението ви се предоставя "КАКВО Е" И "КОГАТО Е НАЛИЧНО" и с всички неизправности и дефекти без гаранция от
      всякакъв вид. В максималната степен, разрешена от приложимото законодателство, PriceX.app, от свое име и от името на
      неговите филиали и неговите и техните съответни лицензодатели и доставчици на услуги, изрично отказва всички гаранции,
      независимо дали е изрично, подразбиращо се, задължително или друго по отношение на Приложението, включително всички подразбиращи се
      гаранции за продаваемост, годност за определена цел, титла и ненарушаване и гаранции, че
      може да възникне в резултат на сделката, хода на изпълнението, употребата или търговската практика. Без ограничение до
      гореизложеното, PriceX.app не предоставя никакви гаранции или гаранции и не представя никакви изявления, че
      Приложението ще отговаря на вашите изисквания, ще постигне желаните резултати, ще бъде съвместимо или ще работи с други
      софтуер, приложения, системи или услуги, работят без прекъсване, отговарят на производителността или надеждността
      стандарти или да са без грешки или че всякакви грешки или дефекти могат или ще бъдат коригирани.</p>
    <p>Без да ограничаваме гореизложеното, нито PriceX.app, нито който и да е доставчик на PriceX.app.
      гарантира от всякакъв вид, изрична или подразбираща се: (i) по отношение на работата или наличността на Приложението или
      информация, съдържание и материали или продукти, включени в тях; (ii) че заявлението ще бъде
      непрекъснато или без грешки; (iii) по отношение на точността, надеждността или валутата на каквато и да е информация или съдържание
      предоставени чрез Приложението; или (iv) че Приложението, неговите сървъри, съдържанието или имейлите, изпратени от
      или от името на PriceX.app не съдържа вируси, скриптове, троянски коне, червеи, злонамерен софтуер или други
      вредни компоненти.</p>
    <p>Някои юрисдикции не позволяват изключването или ограниченията на подразбиращи се гаранции или ограниченията върху
      приложими законови права на потребител, така че някои или всички по-горе изключения и ограничения може да не се прилагат
      за теб.</p>

    <h1>Ограничаване на отговорността</h1>
    <p>Независимо от каквито и да е щети, които бихте могли да понесете, цялата отговорност на PriceX.app и някой от неговите доставчици
      съгласно която и да е разпоредба от настоящото споразумение и вашето изключително средство за защита за всичко гореизложено ще бъде ограничено до
      сума, действително платена от Вас за Приложението.</p>
    <p>В максималната степен, разрешена от приложимото законодателство, в никакъв случай PriceX.app или неговите доставчици не носят отговорност
      всякакви специални, случайни, косвени или последващи щети (включително, но не само, щети
      за загуба на печалба, за загуба на данни или друга информация, за прекъсване на бизнеса, за телесна повреда, за
      загуба на поверителност, произтичаща от или по някакъв начин свързана с използването или невъзможността за използване на Приложението,
      софтуер на трети страни и / или хардуер на трети страни, използван с Приложението или по друг начин във връзка с който и да е друг
      разпоредба на настоящото споразумение), дори ако PriceX.app или който и да е доставчик е бил уведомен за възможността за такова
      щети и дори ако средство за защита се провали от основната си цел.</p>
    <p>Някои държави / юрисдикции не позволяват изключването или ограничаването на случайни или последващи щети, така че
      по-горе ограничение или изключване може да не се отнася за вас.</p>

    <h1>Делимост</h1>
    <p>Ако някоя от разпоредбите на настоящото споразумение се счита за неприложима или невалидна, такава разпоредба ще бъде променена и
      тълкувана за постигане на целите на такава разпоредба във възможно най-голяма степен съгласно приложимото законодателство
      а останалите разпоредби ще продължат с пълна сила и ефект.</p>

    <h1>Отказ</h1>
    <p>Липса на неизпълнение и забавяне на упражняването от страна на която и да е от страните, каквото и да е право или правомощия съгласно
      настоящото споразумение действа като отказ от това право или правомощие. Нито едно или частично упражняване на каквото и да било
      правото или правомощието съгласно настоящото споразумение изключват по-нататъшното упражняване на това или всяко друго право, предоставено тук. В
      в случай на конфликт между настоящото споразумение и всяка приложима покупка или други условия, условията на това
      управлява споразумението.</p>

    <h1>Изменения на настоящото споразумение</h1>
    <p>PriceX.app си запазва правото, по свое усмотрение, да модифицира или замени настоящото споразумение по всяко време. Ако
      преразглеждането е съществено, ще предоставим предизвестие от поне 30 дни преди влизането в сила на нови условия. Какво
      представлява съществена промяна, ще бъде определена по наше собствено усмотрение.</p>
    <p>Продължавайки да осъществявате достъп или да използвате нашето Приложение след влизане в сила на каквито и да било ревизии, вие се съгласявате да бъдете обвързани с
      ревизираните условия. Ако не сте съгласни с новите условия, вече нямате право да използвате Приложението.</p>

    <h1>Приложимо право</h1>
    <p>Законодателството на България, с изключение на нормите за колизия на закона, ще урежда настоящото споразумение и използването от ваша страна на
      Приложение. Вашето използване на Приложението може също да бъде предмет на други местни, държавни, национални или международни
      закони.</p>

    <h1>Промени в това споразумение</h1>
    <p>Ние си запазваме изключителното право да правим промени в настоящото споразумение от време на време. Продължаващият ви достъп и
      използването на уебсайта представлява вашето съгласие да бъдете обвързани и приемате условията
      публикувано в такъв момент. Вие признавате и се съгласявате, че приемате това Споразумение (и всички изменения към него) всяко
      по време на зареждане, достъп или използване на уебсайта. Затова ви препоръчваме да преглеждате редовно настоящото споразумение.</p>
    <p>Ако в рамките на тридесет (30) дни след като публикуваме промени или поправки в настоящото споразумение, решите, че не искате
      да се съгласите с актуализираните условия, можете да оттеглите своето приемане на изменените условия, като ни предоставите писмено
      известие за оттеглянето ви. След като ни предоставите писменото известие за оттеглянето на вашето приемане, вие
      вече не са упълномощени за достъп или използване на уебсайта.</p>

    <h1>Справедливо обезщетение</h1>
    <p>Вие признавате и се съгласявате, че нарушаването на настоящото споразумение би причинило на PriceX.app непоправима вреда, за която
      паричните щети сами по себе си биха били недостатъчни. В допълнение към щетите и всякакви други средства за защита, на които PriceX.app може да
      има право, вие признавате и се съгласявате, че можем да потърсим обезпечителни мерки, за да предотвратим действителните, заплашени или
      в продължаващо нарушение на настоящото споразумение.</p>

    <h1>Заглавия</h1>
    <p>Заглавията в настоящото споразумение са само за справка и не трябва да ограничават обхвата или да засягат по друг начин
      тълкуване на настоящото споразумение.</p>

    <h1>Географски ограничения</h1>
    <p>Компанията е базирана в България и е предоставена за достъп и използване предимно от лица, намиращи се в България, и се
      поддържа спазването на българските закони и разпоредби. Ако използвате уебсайта извън България, вие сте
      единствено и изключително отговорни за спазването на местните закони.</p>

    <h1>Ограничение на времето за подаване на искове</h1>
    <p>Всяка причина за иск или иск, които може да са възникнали от или свързани с това споразумение или уебсайта, трябва да бъде
      започнало в рамките на една (1) година след натрупване на причината за иск, в противен случай такава причина за иск или иск е
      трайно забранен.</p>

    <h1>Цялото споразумение</h1>
    <p>Споразумението представлява цялото споразумение между вас и PriceX.app относно използването на приложението от ваша страна
      и замества всички предишни и едновременни писмени или устни споразумения между вас и PriceX.app.</p>
    <p>Възможно е да подлежите на допълнителни условия, които се прилагат, когато използвате или закупите други услуги на PriceX.app,
      които PriceX.app ще ви предостави по време на такова използване или покупка.</p>
  </div>
</template>

<script>
export default {
  name: "BgEula"
}
</script>