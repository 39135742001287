<template functional>
  <div>
    <h1>Политика за бисквитки</h1>
    <p>Последна промяна 2020-10-24</p>

    <h1>Определения и ключови термини</h1>
    <p>За да ви помогнем да разберете нещата възможно най-ясно в тази Политика за поверителност, всеки път, когато някой от тези условия са
      посочени, са строго дефинирани като:</p>
    <ul>
      <li>„Бисквитка“: малко количество данни, генерирани от уебсайт и запазени от вашия уеб браузър. Използва се, за да
        идентифицираме браузъра ви, осигурим анализ, запомняме информация за вас, като вашия език и
        информация за предпочитания и/или вход.
      </li>
      <li>Фирма: когато тази политика споменава „Компания“, „ние“, „ние“ или „нашата“, това се отнася до PriceX.app, който е
        отговорен за вашата информация съгласно настоящите Общи условия.
      </li>
      <li>Устройство: всяко свързано с интернет устройство, като телефон, таблет, компютър или друго устройство, което може да бъде
        използвани за посещение на PriceX.app и използване на услугите.
      </li>
      <li>Лични данни: всяка информация, която пряко, косвено или във връзка с друга информация -
        включително личен идентификационен номер - дава възможност за идентификация на физическо лице.
      </li>
      <li>Услуга: отнася се до услугата, предоставяна от PriceX.app, както е описано в относителните условия (ако е налично)
        и на тази платформа.
      </li>
      <li>Услуга на трета страна: отнася се до рекламодатели, спонсори на конкурси, промоционални и маркетингови партньори и
        други, които предоставят нашето съдържание или чиито продукти или услуги смятаме, че могат да ви заинтересуват.
      </li>
      <li>Уебсайт: PriceX.app е сайт, който може да бъде достъпен чрез този URL: https://pricex.app</li>
    </ul>


    <h1>Въведение</h1>
    <p>Тази Политика за бисквитките обяснява как PriceX.app и свързаните с него партньори (общо "PriceX.app", "ние", "нас" и
      "нашите"), използват бисквитки и подобни технологии, за да ви разпознаят, когато посещавате нашия уебсайт, включително и без
      ограничение https://pricex.app и всякакви свързани URL адреси, мобилни или локализирани версии и свързани домейни /
      поддомейни („Уебсайтове“). Тази политика обяснява какви са тези технологии и защо ги използваме, както и изборите
      за това как да ги контролираме.</p>

    <h1>Какво представлява бисквитката?</h1>
    <p>„Бисквитката“ е малък текстов файл, който се съхранява на вашия компютър или друго свързано с интернет устройство, за да може
      да идентифицираме браузъра ви, да съставим анализи, да запомните информация за вас, като езиковото ви предпочитание или
      информация за вход. Те са напълно безопасни и не могат да се използват за стартиране на програми или доставяне на вируси на вашия
      устройство.</p>

    <h1>Защо използваме бисквитки?</h1>
    <p>Ние използваме бисквитки на първа страна и/или трети страни на нашия уебсайт за различни цели като:</p>
    <ul>
      <li>За улесняване на работата и функционалността на нашия уебсайт;</li>
      <li>За да подобрим вашия опит с нашия уебсайт и да направим навигацията около тях по-бърза и лесна;</li>
      <li>За да ни позволите да направим потребителско изживяване по добро за вас и за да разберем какво е полезно или представляващо интерес
        за теб;
      </li>
      <li>За да анализираме как се използва нашият уебсайт и как най-добре можем да го подобрим;</li>
      <li>Да идентифицира бъдещи перспективи и да персонализира маркетинговите и продажбените взаимодействия с тях;</li>
      <li>За да улесните приспособяването на онлайн рекламата към вашите интереси.</li>
      <li>Вие: физическо или юридическо лице, което е регистрирано в PriceX.app, за да използва Услугите.</li>
    </ul>

    <h1>Какъв тип бисквитки използва PriceX.app?</h1>
    <p>„Бисквитките“ могат да бъдат „бисквитки“ на сесията или постоянни „бисквитки“. Бисквитката на сесията изтича автоматично, когато вие
      затворете браузъра си. Постоянна бисквитка ще остане, докато изтече или изтриете своите бисквитки. Изтичане
      датите са определени в самите бисквитки; някои могат да изтекат след няколко минути, докато други могат да изтекат след
      няколко години. Бисквитките, поставени от уебсайта, който посещавате, се наричат „бисквитки от първа страна“.</p>
    <p>Строго необходимите бисквитки са необходими за функционирането на нашия уебсайт и не могат да бъдат изключени в нашите системи.
      Те са от съществено значение, за да ви позволят да се придвижвате из уебсайта и да използвате неговите функции. Ако премахнете или
      деактивирайте тези бисквитки, не можем да гарантираме, че ще можете да използвате нашия уебсайт.</p>
    <p>Ние използваме следните видове бисквитки в нашия уебсайт:</p>
    <h1>Основни бисквитки</h1>
    <p>Използваме основни бисквитки, за да накараме уебсайта ни да работи. Тези бисквитки са строго необходими за активиране на ядрото
      функционалност като сигурност, управление на мрежата, вашите предпочитания за бисквитки и достъпност. Без тях вие
      не биха могли да използват основни услуги. Можете да ги деактивирате, като промените настройките на браузъра си, но това може
      повлияват как функционират уебсайтовете.</p>
    <h1>Бисквитки за изпълнение и функционалност</h1>
    <p>Тези бисквитки се използват за подобряване на ефективността и функционалността на нашия уебсайт, но не са от съществено значение за тях
      използване. Без тези „бисквитки“ обаче някои функционалности като видеоклипове може да станат недостъпни или бихте били
      изисква да въведете данните си за вход всеки път, когато посещавате уебсайта, тъй като не бихме могли да го запомним
      сте били влезли преди това.</p>
    <h1>Маркетингови бисквитки</h1>
    <p>Тези маркетингови бисквитки, базирани на акаунт, ни дават възможност да идентифицираме бъдещите перспективи и да персонализираме продажбите и маркетинга
      взаимодействия с тях.</p>
    <h1>Бисквитки за анализ и персонализиране</h1>
    <p>Тези бисквитки събират информация, която се използва, за да ни помогне да разберем как се използва нашият уебсайт,
      ефективни за нашите маркетингови кампании или за да ни помогнете да персонализираме нашия уебсайт за вас.</p>
    <p>Използваме бисквитки, обслужвани от Google Analytics, за да събираме ограничени данни директно от браузъри за крайни потребители, за да ни позволят
      по-добре да разберем как използвате нашия уебсайт. Допълнителна информация за това как Google събира и използва тези данни може да бъде
      намерено на: https://www.google.com/policies/privacy/partners/. Можете да се откажете от всички анализи, поддържани от Google
      на нашите уебсайтове, като посетите: https://tools.google.com/dlpage/gaoptout.</p>
    <h1>Рекламни бисквитки</h1>
    <p>Тези бисквитки събират информация с течение на времето за вашата онлайн активност на уебсайта и други онлайн услуги
      за да направите онлайн рекламите по-подходящи и ефективни за вас. Това е известно като реклама въз основа на интереси.
      Те също изпълняват функции като предотвратяване на непрекъснатото повторно появяване на една и съща реклама и гарантиране, че рекламите са такива
      правилно показани за рекламодатели. Без бисквитки е наистина трудно за рекламодателя да достигне до своята аудитория,
      или да знаете колко реклами са показани и колко кликвания са получили.</p>
    <h1>Бисквитка в социалните медии</h1>
    <p>Тези бисквитки се използват, когато споделяте информация с помощта на бутон за споделяне в социални медии или бутон „харесване“ на нашия
      Уебсайт или свързвате акаунта си или се ангажирате с нашето съдържание на или чрез уебсайт за социални мрежи, като например
      Facebook, Twitter или Google+. Социалната мрежа ще запише, че сте направили това. Тези бисквитки също могат да
      включват определен код, който е поставен на платформата, за да помогне за проследяване на реализациите от реклами, оптимизиране на реклами въз основа
      на събрани данни, изграждане на целеви аудитории за бъдещи реклами и ремаркетинг на квалифицирани потребители, които вече са го направили
      предприе определени действия на платформата.</p>
    <h1>Бисквитки на трети страни</h1>
    <p>Някои бисквитки, които са зададени на нашия уебсайт, не се задават на първо място от PriceX.app. Уебсайтовете могат
      да бъде вградено със съдържание от трети страни за обслужване на реклама. Тези доставчици на услуги на трети страни могат да зададат
      собствените си бисквитки във вашия уеб браузър. Доставчиците на услуги на трети страни контролират много от производителността и
      функционални, рекламни, маркетингови и аналитични бисквитки, описани по-горе. Ние не контролираме използването на
      бисквитки на трети страни като бисквитки могат да бъдат достъпни само от третата страна, която първоначално ги е задала.</p>
    <h1>Как можете да управлявате бисквитките?</h1>
    <p>Повечето браузъри ви позволяват да контролирате бисквитките чрез техните предпочитания за „настройки“. Ако обаче ограничите
      способността на уебсайтовете да задават бисквитки, може да влошите цялостното си потребителско изживяване, тъй като вече няма да бъде
      персонализирани за вас. Освен това може да ви попречи да запазвате персонализирани настройки като информация за вход. Браузър
      производителите предоставят помощни страници, свързани с управлението на бисквитки в техните продукти.</p>

    <h1>Блокиране и деактивиране на бисквитки и подобни технологии</h1>
    <p>Където и да се намирате, можете също да настроите браузъра си да блокира бисквитки и подобни технологии, но това действие
      може да блокира нашите основни бисквитки и да попречи на нашия уебсайт да функционира правилно, а вие може да не успеете
      да използват пълноценно всички негови функции и услуги. Трябва също да знаете, че може да загубите и някоя запазена
      информация (напр. запазени данни за вход, предпочитания на сайта), ако блокирате бисквитки в браузъра си. Различните
      браузъри ви предоставят различни контроли. Деактивирането на бисквитка или категория бисквитки не изтрива
      бисквитка от вашия браузър, ще трябва да направите това сами от вашия браузър, трябва да посетите вашето
      меню за помощ на браузъра за повече информация.</p>

    <h1>Промени в нашата политика за бисквитките</h1>
    <p>Възможно е да променим нашата Услуга и политики и може да се наложи да направим промени в тази Политика за бисквитки, така че те
      точно отразяват нашата услуга и политики. Освен ако законът не изисква друго, ние ще Ви уведомим (например,
      чрез нашата услуга), преди да направим промени в тази Политика за бисквитките и да ви дадем възможност да ги прегледате
      преди да влязат в сила. След това, ако продължите да използвате Услугата, ще бъдете обвързани от актуализираната
      Политика. Ако не искате да се съгласите с тази или която и да е актуализирана Политика за бисквитките, можете да изтриете акаунта си.</p>

    <h1>Вашето съгласие</h1>
    <p>Като използвате нашия уебсайт, регистрирате акаунт или правите покупка, вие се съгласявате с нашата Политика за бисквитки и
      словия.</p>
  </div>
</template>

<script>
export default {
  name: "BgCookiesPolicy"
}
</script>