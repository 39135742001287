<template functional>
  <div>
    <h1>Политика за поверителност</h1>
    <p>Последна промяна 2020-10-24</p>

    <p> PriceX.app („ние“ или „наш“) се ангажира да защитава вашата поверителност. Тази Политика за поверителност обяснява как вашият
      личната информация се събира, използва и разкрива от PriceX.app.</p>
    <p>Тази Политика за поверителност се отнася за нашия уебсайт и свързаните с него поддомейни (включително нашата „Услуга“) и приложение, PriceX.app.
      С достъпа или използването на нашата услуга вие потвърждавате, че сте прочели, разбрали и
      приемаме нашето събиране, съхранение, използване и разкриване на вашата лична информация, както е описано в тази поверителност
      Политика и нашите Общи условия.</p>

    <h1>Определения и ключови термини</h1>
    <p>За да ви помогнем да разберете нещата възможно най-ясно в тази Политика за поверителност, всеки път, когато някой от тези условия са
      посочени, са строго дефинирани като:</p>
    <ul>
      <li>„Бисквитка“: малко количество данни, генерирани от уебсайт и запазени от вашия уеб браузър. Използва се, за да
        идентифицираме браузъра ви, осигурим анализ, запомняме информация за вас, като вашия език и
        информация за предпочитания и/или вход.
      </li>
      <li>Фирма: когато тази политика споменава „Компания“, „ние“, „ние“ или „нашата“, това се отнася до PriceX.app, който е
        отговорен за вашата информация съгласно настоящите Общи условия.
      </li>
      <li>Държава: където са базирани PriceX.app или собствениците / основателите на PriceX.app, в този случай е България</li>
      <li>Устройство: всяко свързано с интернет устройство, като телефон, таблет, компютър или друго устройство, което може да бъде
        използвани за посещение на PriceX.app и използване на услугите.
      </li>
      <li>Клиент: отнася се до компанията, организацията или лицето, което се регистрира за използване на услугата PriceX.app,
        за да управлява взаимоотношенията с нашите потребители или търговци на услуги.
      </li>
      <li>IP адрес: На всяко устройство, свързано към Интернет, се присвоява номер, известен като интернет протокол (IP)
        адрес. Тези номера обикновено се задават в географски блокове. Често може да се използва IP адрес
        идентифицирайки местоположението, от което дадено устройство се свързва с интернет.
      </li>
      <li>Персонал: отнася се за лицата, които са наети от PriceX.app или са по договор изпълняват
        услуга от името на една от страните.
      </li>
      <li>Лични данни: всяка информация, която пряко, косвено или във връзка с друга информация -
        включително личен идентификационен номер - дава възможност за идентификация на физическо лице.
      </li>
      <li>Услуга: отнася се до услугата, предоставяна от PriceX.app, както е описано в относителните условия (ако е налично)
        и на тази платформа.
      </li>
      <li>Услуга на трета страна: отнася се до рекламодатели, спонсори на конкурси, промоционални и маркетингови партньори и
        други, които предоставят нашето съдържание или чиито продукти или услуги смятаме, че могат да ви заинтересуват.
      </li>
      <li>Уебсайт: PriceX.app е сайт, който може да бъде достъпен чрез този URL: https://pricex.app</li>
      <li>Вие: физическо или юридическо лице, което е регистрирано в PriceX.app, за да използва Услугите.</li>
    </ul>

    <h1>Каква информация събираме?</h1>
    <p>Ние събираме информация от вас, когато посетите нашия уебсайт, регистрирате се на нашия сайт, направите поръчка, абонирате се за
      нашия бюлетин, отговорете на анкета или попълнете формуляр.</p>
    <ul>
      <li>Име / прякор</li>
      <li>Телефонни номера</li>
      <li>Email адреси</li>
      <li>Адреси за плащане и доставка</li>
      <li>Години</li>
    </ul>
    <p>Също така събираме информация от мобилни устройства за по-добро потребителско изживяване, въпреки че тези функции са
      напълно по избор:</p>
    <ul>
      <li>Местоположение (GPS): Данните за местоположението помагат да се създаде точно представяне на вашите интереси и това може да бъде
        използвани за предоставяне на по-целенасочени и подходящи реклами на потенциални клиенти.
      </li>
    </ul>

    <h1>Как използваме информацията, която събираме?</h1>
    <p>Всяка информация, която събираме от вас, може да бъде използвана по един от следните начини:</p>
    <ul>
      <li>Да персонализираме вашето преживяване (вашата информация ни помага да отговорим по-добре на вашите индивидуални нужди)</li>
      <li>За да подобрим нашия уебсайт (ние непрекъснато се стремим да подобряваме предлаганите от нас уебсайтове въз основа на информацията и
        обратна връзка, която получаваме от вас)
      </li>
      <li>За подобряване на обслужването на клиентите (вашата информация ни помага да отговорим по-ефективно на вашето обслужване на клиенти
        искания и нужди от подкрепа)
      </li>
      <li>За обработка на транзакции и поръчки</li>
      <li>За администриране на конкурс, промоция, проучване или друга функция на сайта</li>
      <li>За изпращане на периодични имейли</li>
      <li>При направена от вас поръчка ще изпратим нужната информация за доставка до търговеца/търговците към които правите поръчка.</li>
    </ul>

    <h1>Кога PriceX.app използва информация за крайния потребител от трети страни?</h1>
    <p>PriceX.app ще събира данни за крайния потребител, необходими за предоставяне на услугите PriceX.app на нашите клиенти.</p>
    <p>Крайните потребители могат доброволно да ни предоставят информация, която са предоставили на уебсайтовете на социалните медии. Ако ни
      предоставете такава информация, ние можем да събираме обществено достъпна информация от социалните медии
      уебсайтове, които сте посочили. Можете да контролирате каква част от вашите данни в социалните медии са публични на тези уебсайтове и направите промяна по настройката за поверителност.</p>

    <h1>Кога PriceX.app използва информация за клиенти от трети страни?</h1>
    <p>Получаваме определена информация от трети страни, когато свържете профила си с нас. Например, когато споделите имейла си
      с нас, за да станете клиент на PriceX.app, получаваме информация от трети страни, които
      предоставят автоматизирани услуги за откриване на измами.</p>

    <h1>Споделяме ли информацията, която събираме с трети страни?</h1>
    <p>Можем да споделяме информацията, която събираме, както лична, така и нелична, с трети страни като
      рекламодатели, спонсори на конкурси, промоционални и маркетингови партньори и други, които предоставят нашето съдържание или чието
      продукти или услуги, които смятаме, че могат да ви заинтересуват. Може също да го споделим с нашите настоящи и бъдещи свързани лица
      компании и бизнес партньори и ако участваме в сливане, продажба на активи или друга бизнес
      реорганизация, ние също можем да споделяме или прехвърляме вашата лична и нелична информация към нашата
      наследници по интерес.</p>
    <p>Можем да ангажираме доверени доставчици на услуги на трети страни да изпълняват функции и да ни предоставят услуги, като например
      хостинг и поддръжка на нашите сървъри и уебсайта, съхранение и управление на бази данни, управление на електронна поща, съхранение
      маркетинг, обработка на кредитни карти, обслужване на клиенти и изпълнение на поръчки за продукти и услуги, които можете
      да закупите през уебсайта. Вероятно ще споделим вашата лична информация, а вероятно и нелична
      информация, с тези трети страни, за да им даде възможност да извършват тези услуги за нас и за вас.</p>
    <p>Можем да споделяме части от данните на лог файл, включително IP адреси, за целите на анализа с трети страни
      като партньори за уеб анализ, разработчици на приложения и рекламни мрежи. Ако вашият IP адрес е споделен, може да се
      използва за оценка на общо местоположение и други технологични данни, като скорост на връзката, независимо дали сте посетили
      уебсайта на споделено място и типа на устройството, използвано за посещение на уебсайта. Те могат да агрегират
      информация за нашата реклама и това, което виждате на уебсайта и след това предоставяне на одит, изследвания и
      отчитане за нас и нашите рекламодатели. Може също да разкрием лична и нелична информация за вас
      държавни служители или служители на правоприлагащите органи или частни лицам, ако по наша преценка смятаме, че е необходимо или
      подходящо, за да отговорим на искове, съдебен процес (включително призовки), за да защитим нашите права и
      интереси или интереси на трета страна, безопасността на обществеността или което и да е лице, за предотвратяване или спиране на незаконно,
      неетична или правно действаща дейност, или да се съобразяват по друг начин с приложимите съдебни разпореждания, закони, правила и
      регламенти.</p>

    <h1>Къде и кога се събира информация от клиенти и крайни потребители?</h1>
    <p>PriceX.app ще събира лична информация, която ни изпращате. Може да получим и лична информация
      за вас от трети страни, както е описано по-горе.</p>

    <h1>Как използваме вашия имейл адрес?</h1>
    <p>Изпращайки своя имейл адрес на този уебсайт, вие се съгласявате да получавате имейли от нас. Можете да анулирате вашето
      участие във всеки от тези имейл списъци по всяко време, като щракнете върху връзката за отказ или друга за отписване
      опция, която е включена в съответния имейл. Изпращаме имейли само на хора, които са ни упълномощили да се свържем
      или директно, или чрез трета страна. Ние не изпращаме нежелани търговски имейли, защото мразим
      спам колкото и вие. Изпращайки своя имейл адрес, вие също се съгласявате да ни разрешите да използваме вашия имейл адрес
      за насочване на клиентска аудитория към сайтове като Facebook, където показваме персонализирана реклама на конкретни хора
      които са се включили да получават съобщения от нас. Имейл адреси, подадени само чрез страницата за обработката на поръчката
      ще се използват с единствената цел да ви изпратим информация и актуализации, свързани с вашата поръчка. Ако
      Вие обаче ни предоставите същия имейл чрез друг метод, ние можем да го използваме за всяка от целите
      посочени в тази Политика. Забележка: Ако по всяко време искате да се отпишете от получаването на бъдещи имейли, ние
      включваме подробни инструкции за отписване в долната част на всеки имейл.</p>

    <h1>Колко дълго съхраняваме вашата информация?</h1>
    <p>Ние съхраняваме вашата информация само докато ни е необходима, за да ви предоставим PriceX.app и да изпълним целите
      описани в тази политика. Това важи и за всеки, с когото споделяме вашата информация и който отговаря за
      предоставяне на услуги от наше име. Когато вече няма нужда да използваме вашата информация и няма нужда да я пазим
      за да се съобрази с нашите законови или регулаторни задължения, ние ще го премахнем от нашите системи или ще го обезличим
      така че да не можем да Ви идентифицираме.</p>

    <h1>Как защитаваме вашата информация?</h1>
    <p>Ние прилагаме различни мерки за сигурност, за да поддържаме безопасността на вашата лична информация, когато поставите
      поръчате или въведете, изпратете или влезете в личната си информация. Ние прилагаме използването на защитен сървър. Всяка доставена
      чувствителна/кредитна информация се предава чрез технологията Secure Socket Layer (SSL) и след това се криптира в нашата
      Базата данни на доставчиците на платежни шлюзове и е достъпна само за оторизираните със специални права за достъп до тях
      системи които са длъжни да пазят информацията поверителна. След транзакция вашата лична информация
      (кредитни карти, номера на социално осигуряване, финансови документи и др.) никога не се съхранява в архив. Не можем обаче да осигурим или
      гарантираме абсолютната сигурност на всяка информация, която предавате на PriceX.app, или гарантираме, че вашата информация
      в Услугата не може да бъде достъпна, разкрита, променена или унищожена от нарушение на някой от нашите физически,
      технически или управленски предпазни мерки.</p>

    <h1>Може ли моята информация да бъде прехвърлена в други държави?</h1>
    <p>PriceX.app е регистриран в България. Информация, събрана чрез нашия уебсайт, чрез пряко взаимодействие с вас, или
      от използването на нашите помощни услуги могат да бъдат прехвърляни от време на време в нашите офиси или персонал, или в трети
      страни, разположени по целия свят и могат да бъдат гледани и хоствани навсякъде по света, включително държави
      които не могат да имат закони с обща приложимост, регулиращи използването и трансфера на такива данни. Най-пълно
      в степента, разрешена от приложимото законодателство, като използвате някое от горните, вие доброволно се съгласявате с трансграничния
      трансфер и хостинг на такава информация.</p>

    <h1>Информацията, събрана чрез услугата PriceX.app, сигурна ли е?</h1>
    <p>Вземаме предпазни мерки, за да защитим сигурността на вашата информация. Имаме физически, електронни и управленски
      процедури за защита, предотвратяване на неоторизиран достъп, поддържане на сигурността на данните и правилно използване на вашата
      информация. Нито хората, нито системите за сигурност обаче са надеждни, включително системите за криптиране.
      Освен това хората могат да извършват умишлени престъпления, да правят грешки или да не спазват политиките. Следователно, докато ние
      правим разумни усилия за защита на вашата лична информация, ние не можем да гарантираме абсолютната й сигурност.</p>

    <h1>Мога ли да актуализирам или коригирам информацията си?</h1>
    <p>Правото, които трябва за актуализации или корекции на информацията, която PriceX.app събира, зависят от вашата
      връзка с PriceX.app. Персоналът може да актуализира или коригира информацията си, както е подробно описано в нашата вътрешна информационна
      политики за заетост на фирмите.</p>
    <p>Клиентите имат право да поискат ограничаване на определени употреби и разкриване на лични данни, както следва.
      Можете да се свържете с нас, за да (1) актуализирате или коригирате личните си данни,
      (2) променете предпочитанията си по отношение на комуникациите и друга информация, от която получавате,
      или (3) да изтриете личната информация, поддържана за вас в нашите системи (при спазване на
      следващия параграф), като анулирате акаунта си. Такива актуализации, корекции, промени и изтривания няма да имат
      ефект върху друга информация, която поддържаме, или информация, която сме предоставили на трети страни
      в съответствие с тази Политика за поверителност преди подобна актуализация, корекция, промяна или изтриване. За да защитите вашата
      поверителност и сигурност, може да предприемем разумни стъпки (като например искане на уникална парола), за да потвърдим вашата
      самоличност, преди да ви предоставим достъп до профил или да извършите корекции. Вие носите отговорност за поддържането на
      тайната на вашата уникална парола и информация за акаунта по всяко време.</p>
    <p>Трябва да знаете, че технологично не е възможно да се премахне всеки запис на информацията
      които сте ни предоставили на нашата система. Необходимостта от архивиране на нашите системи за защита на информацията от неволно
      загуба означава, че копие от вашата информация може да съществува в неизтриваема форма, което ще бъде трудно или невъзможно
      за да се намери. Веднага след получаване на вашата заявка, цялата лична информация, съхранявана в бази данни,
      активно използвайки и други лесно достъпни носители ще бъдат актуализирани, коригирани, променени или изтрити, според случая,
      веднага щом и доколкото това е разумно и технически осъществимо.</p>
    <p>Ако сте краен потребител и искате да актуализирате, изтриете или да получите каквато и да е информация, която имаме за вас, можете да го направите до
      свързване с организацията, на която сте клиент.</p>
    <h1>Продажба на бизнес</h1>
    <p>Ние си запазваме правото да прехвърлим информация на трета страна в случай на продажба, сливане или друго прехвърляне на
      всички или по същество всички активи на PriceX.app или някое от неговите корпоративни филиали (както е дефинирано тук), или
      тази част от PriceX.app или някое от нейните корпоративни филиали, за които се отнася Услугата, или в случай че
      прекратяваме бизнеса си или подаваме петиция или сме подали срещу нас петиция в несъстоятелност, реорганизация
      или подобна процедура, при условие че третата страна се съгласи да се придържа към условията на тази Политика за поверителност.</p>

    <h1>Съдружници</h1>
    <p>Можем да разкрием информация (включително лична информация) за вас на нашите корпоративни филиали. За целите
      от настоящата Политика за поверителност, "Корпоративен филиал" означава всяко физическо или юридическо лице, което пряко или косвено контролира,
      се контролира от или е под общ контрол с PriceX.app, независимо дали е собственост или по друг начин. Информацията
      във връзка с вас, която предоставяме на нашите корпоративни филиали, ще бъдат третирани от тези корпоративни филиали в
      в съответствие с условията на тази Политика за поверителност.</p>

    <h1>Приложимо право</h1>
    <p>Настоящата Политика за поверителност се урежда от законите на Република България, без оглед на нейните конфликтни разпоредби. Вие се съгласявате
      до изключителната юрисдикция на съдилищата във връзка с всяко действие или спор, възникнал между страните
      съгласно или във връзка с настоящата Политика за поверителност, с изключение на тези лица, които могат да имат права да предявят искове
      съгласно Privacy Shield или швейцарско-американската рамка.</p>
    <p>Законодателството на Република България, с изключение на неговите стълкновителни норми, урежда настоящото споразумение и използването от вас на уебсайта.
      Използването на уебсайта ви може да бъде предмет и на други местни, държавни, национални или международни закони.</p>
    <p>Като използвате PriceX.app или се свържете директно с нас, вие потвърждавате, че приемате тази Политика за поверителност. Ако не
      приемете тази Политика за поверителност, не трябва да се ангажирате с нашия уебсайт или да използвате нашите услуги. Продължаване на използването на
      уебсайта, пряка ангажираност с нас или след публикуване на промени в тази Политика за поверителност, които не го правят
      значително повлияе на използването или разкриването на вашата лична информация ще означава, че приемате промените.</p>

    <h1>Вашето съгласие</h1>
    <p>Актуализирахме нашата Политика за поверителност, за да ви предоставим пълна прозрачност на това, което се задава, когато посещавате
      нашия сайт и как се използва. С използването на нашия уебсайт, регистрация на акаунт или извършване на покупка вие се съгласявате с това
      съгласие с нашата Политика за поверителност и съгласие с нейните условия.
    </p>

    <h1>Връзки към други уебсайтове</h1>
    <p>Тази Политика за поверителност се отнася само за Услугите. Услугите могат да съдържат връзки към други уебсайтове, които не се експлоатират
      или контролирани от PriceX.app. Ние не носим отговорност за съдържанието, точността или мненията, изразени в такива
      уебсайтове и такива уебсайтове не се разследват, наблюдават или проверяват за точност или пълнота от нас.
      Моля, не забравяйте, че когато използвате връзка за преминаване от Услугите към друг уебсайт, нашата Политика за поверителност вечее не
      е в сила. Вашето сърфиране и взаимодействие на всеки друг уебсайт, включително тези, които имат връзка към нашия
      платформа, е предмет на собствените правила и политики на този уебсайт. Такива трети страни могат да използват свои собствени бисквитки или
      други методи за събиране на информация за вас.</p>
    <h1>Реклама</h1>
    <p>Този уебсайт може да съдържа реклами на трети страни и връзки към сайтове на трети страни. PriceX.app не прави никакви
      проверки относно точността или годността на която и да е информация, съдържаща се в тези реклами или
      сайтове и не поема никаква отговорност или отговорност за поведението или съдържанието на тези реклами и
      сайтове и предложенията, направени от трети страни.</p>
    <p>Рекламата поддържа PriceX.app и много от уебсайтовете и услугите, които използвате безплатно. Работим усилено, за да се уверим,
      че рекламите са безопасни, ненатрапчиви и възможно най-подходящи.</p>
    <p>Реклами от трети страни и връзки към други сайтове, където се рекламират стоки или услуги, не са препоръки
      или препоръки от PriceX.app на сайтовете, стоките или услугите на трети страни. PriceX.app не поема отговорност
      за съдържанието на някоя от рекламите, направените обещания или качеството / надеждността на предлаганите продукти или услуги
      във всички реклами.</p>

    <h1>Бисквитки за реклама</h1>
    <p>Тези бисквитки събират информация с течение на времето за вашата онлайн активност на уебсайта и други онлайн услуги
      за да направят онлайн рекламите по-подходящи и ефективни за вас. Това е известно като реклама въз основа на интереси.
      Те също изпълняват функции като предотвратяване на непрекъснатото повторно появяване на една и съща реклама и гарантиране, че рекламите са
      правилно показани за рекламодатели. Без бисквитки е наистина трудно за рекламодателя да достигне до своята аудитория,
      или да знаете колко реклами са показани и колко кликвания са получили.</p>
    <h1>Бисквитки</h1>
    <p>PriceX.app използва „бисквитки“, за да идентифицира областите от нашия уебсайт, които сте посетили. Бисквитката е малко парче
      от данни, съхранявани на вашия компютър или мобилно устройство на вашия уеб браузър. Използваме бисквитки, за да подобрим ефективността
      и функционалността на нашия уебсайт, но не са от съществено значение за неговото използване. Без тези бисквитки обаче е сигурно, че
      функционалност като видеоклипове може да стане недостъпна или ще трябва да въвеждате данните си за вход всеки път
      посещавайки уебсайта, тъй като не бихме могли да си спомним, че сте били влезли преди това. Повечето уеб браузъри
      могат да бъде настроени да деактивира използването на бисквитки. Ако деактивирате „бисквитките“, може да нямате достъп до него
      функционалността на нашия уебсайт правилно или изобщо. Ние никога не поставяме лична информация в
      бисквитки.</p>

    <h1>Блокиране и деактивиране на бисквитки и подобни технологии</h1>
    <p>Където и да се намирате, можете също да настроите браузъра си да блокира бисквитки и подобни технологии, но това действие
      може да блокира нашите основни бисквитки и да попречи на нашия уебсайт да функционира правилно, а вие може да не успеете
      използват пълноценно всички негови функции и услуги. Трябва също да знаете, че може да загубите и някои запазени
      информация (напр. запазени данни за вход, предпочитания на сайта), ако блокирате бисквитки в браузъра си. Различните
      браузърите ви предоставят различни контроли. Деактивирането на бисквитка или категория бисквитки не изтрива
      бисквитките от вашия браузър и ще трябва да направите това сами от вашия браузър. Трябва да посетите вашето
      менюто за помощ на браузъра за повече информация.</p>
    <h1>Услуги за ремаркетинг</h1>
    <p>Използваме услуги за ремаркетинг. Какво е ремаркетинг? В дигиталния маркетинг ремаркетингът (или пренасочването) е
      практика на показване на реклами в интернет на хора, които вече са посетили вашия уебсайт. Позволява
      компанията да изглежда така, сякаш „следват“ хора в интернет, като показват реклами в уебсайтовете и платформите които
      те използват най-много.</p>
    <h1>Информация на плащане</h1>
    <p>По отношение на всякакви данни за обработка на плащания, които сте ни предоставили, ние се ангажираме с това
      поверителната информация да се съхранява по възможно най-сигурния начин.</p>
    <h1>Поверителност на децата</h1>
    <p>Ние не се обръщаме към никого под 13-годишна възраст. Ние не събираме съзнателно лична информация
      от всеки, който е на възраст под 13 години. Ако сте родител или настойник и сте наясно, че детето ви е осигурило
      Нас с лични данни, моля свържете се с нас. Ако разберем, че сме събрали Лични данни от някого
      под 13-годишна възраст без проверка на съгласието на родителите, ние предприемаме стъпки за премахване на тази информация от нашия
      сървъри.</p>
    <h1>Промени в нашата политика за поверителност</h1>
    <p>Може да променим нашата Услуга и политики и може да се наложи да направим промени в настоящата Политика за поверителност, така че те
      точно да отразяват нашата услуга и политики. Освен ако законът не изисква друго, ние ще Ви уведомим (например,
      чрез нашата услуга), преди да направим промени в тази Политика за поверителност и да ви дадем възможност да ги прегледате
      преди да влязат в сила. След това, ако продължите да използвате Услугата, ще бъдете обвързани от актуализираната Политика за поверителност.
      Ако не искате да се съгласите с тази или която и да е актуализирана Политика за поверителност, можете да изтриете акаунта си.</p>

    <h1>Услуги на трети страни</h1>
    <p>Можем да показваме, включваме или правим достъпно съдържание на трети страни (включително данни, информация, приложения и
      други продукти) или продоставяме връзки към уебсайтове или услуги на трети страни („Услуги на трети страни“).</p>
    <p>Вие признавате и се съгласявате, че PriceX.app не носи отговорност за услуги на трети страни, включително тяхната
      точност, пълнота, навременност, валидност, спазване на авторските права, законност, благоприличие, качество или каквото и да било друго
      негов аспект. PriceX.app не поема и няма да носи никаква отговорност или отговорност към вас или каквато и да е друга
      друго лице или субект за услуги на трети страни.</p>
    <p>Услугите на трети страни и връзките към тях се предоставят единствено за ваше удобство и вие имате достъп до тях и ги използвате
      изцяло на ваш риск и при спазване на условията на такива трети страни.</p>
    <h1>Facebook Pixel</h1>
    <p>Facebook pixel е инструмент за анализ, който ви позволява да измервате ефективността на вашата реклама чрез
      разбиране на действията, които хората предприемат във вашия уебсайт. Можете да използвате пиксела за: Да се уверете, че рекламите Ви се показват на
      правилните хора. Facebook pixel може да събира информация от вашето устройство, когато използвате услугата. Facebook
      pixel събира информация, която се съхранява в съответствие с нейната Политика за поверителност</p>
    <h1>Проследяващи технологии</h1>
    <ul>
      <li>Google Maps API
        <br><br>
        <p>API на Google Maps е надежден инструмент, който може да се използва за създаване на персонализирана карта, карта с възможност за търсене, регистрация,
          функции за синхронизация на местоположение, планиране маршрути или създайте търсене по адрес.</p>
        <p>Приложният програмен интерфейс (API) на Google Maps може да събира информация от вас и от вашето устройство от съображения за сигурност.</p>
        <p>API на Google Maps събира информация, която се съхранява в съответствие с нейната Политика за поверителност</p></li>
      <li>Бисквитки
        <br><br>
        <p>Ние използваме бисквитки, за да подобрим производителността и функционалността на нашата платформа, но не са от съществено значение за
          тяхното използване. Без тези „бисквитки“ обаче някои функции като видеоклипове могат да станат недостъпни или
          ще трябва да въвеждате данните си за вход всеки път, когато посещавате платформата, тъй като ние не бихме могли
          да запомним, че сте влизали преди това.</p>
      </li>
      <li>Локално хранилище
        <br><br>
        <p>Локалното хранилище, понякога известно като DOM съхранение, предоставя на уеб приложенията методи и протоколи за съхранение на
          данни от страна на клиента. Уеб съхранението поддържа постоянно съхранение на данни, подобно на бисквитките, но в голяма степен
          подобрен капацитет и никаква информация, съхранявана в HTTP заявката.</p></li>
      <li>Сесии
        <br><br>
        <p>PriceX.app използва „Сесии“, за да идентифицира областите от нашия уебсайт, които сте посетили. Сесията е a
          малка част от данните, съхранявани на вашия компютър или мобилно устройство от вашия уеб браузър.</p></li>
    </ul>
    <h1>Информация за Общия регламент за защита на данните (GDPR)</h1>
    <p>Може да събираме и използваме информация от вас, ако сте от Европейското икономическо пространство (ЕИП) и в този
      раздел от нашата Политика за поверителност ще обясним как точно и защо се събират тези данни и как ние
      поддържайте тези данни под защита срещу възпроизвеждане или използване по грешен начин.</p>

    <h1>Какво е GDPR?</h1>
    <p>GDPR е общоевропейски закон за поверителност и защита на данните, който регламентира как данните на жителите на ЕС са защитени от
      компании и подобрява контрола на личните данни на жителите на ЕС.</p>
    <p>GDPR е от значение за всяка глобално действаща компания, а не само за базираните в ЕС предприятия и жителите на ЕС. Нашите
      Данните на клиентите са важни, независимо къде се намират, поради което внедрихме GDPR
      контрол като наш изходен стандарт за всички наши операции в световен мащаб.</p>

    <h1>Какво представляват личните данни?</h1>
    <p>Всички данни, които се отнасят до идентифицируемо или идентифицирано лице. GDPR обхваща широк спектър от информация
      които биха могли да се използват самостоятелно или в комбинация с друга информация за идентифициране на лице.
      Личните данни се простират извън името или имейл адреса на човек. Някои примери включват финансова информация,
      политически мнения, генетични данни, биометрични данни, IP адреси, физически адрес, сексуална ориентация и
      етническа принадлежност.</p>
    <p>Принципите за защита на данните включват изисквания като:</p>
    <ul>
      <li>Събраните лични данни трябва да бъдат обработвани по справедлив, законен и прозрачен начин и трябва да се използват само в
        начин, който човек разумно би очаквал.
      </li>
      <li>Личните данни трябва да се събират само за изпълнение на определена цел и трябва да се използват само за това
        предназначение. Организациите трябва да посочат защо се нуждаят от личните данни, когато ги събират.
      </li>
      <li>Личните данни не трябва да се съхраняват по-дълго от необходимото, за да изпълнят целта си.</li>
      <li>Хората, обхванати от GDPR, имат право на достъп до собствените си лични данни. Те също могат да поискат копие на
        техните данни и данните им да бъдат актуализирани, изтрити, ограничени или преместени в друга организация.
      </li>
    </ul>

    <h1>Защо GDPR е важен?</h1>
    <p>GDPR добавя някои нови изисквания относно това как компаниите трябва да защитават личните данни на отделните лица, как тя се
      събира и обработва. Той също така повишава залозите за спазване, като увеличава прилагането и налага по-големи
      глоби за нарушение. Отвъд тези факти това е просто правилното нещо. В PriceX.app ние силно вярваме в това
      че поверителността на вашите данни е много важна и вече имаме стабилни практики за сигурност и поверителност, които работят
      извън изискванията на този нов регламент.</p>

    <h1>Права на отделния субект на данни - Достъп до данни, преносимост и изтриване</h1>
    <p>Ние се ангажираме да помогнем на нашите клиенти да изпълнят изискванията на GDPR за правата на субекта на данни. PriceX.app обработва
      или съхранява всички лични данни в напълно проверени доставчици, съвместими с DPA. Ние съхраняваме всички разговори и лични
      данни до 6 години, освен ако акаунтът ви не бъде изтрит. В този случай ние изхвърляме всички данни в съответствие с
      нашите Условия за ползване и Политика за поверителност, но няма да ги съхраняваме по-дълго от 1 година.</p>
    <p>Ако направите заявка, имаме един месец да ви отговорим. Ако искате да упражните някое от тези права,
      Моля, свържете се с нас.</p>
    <p>Ние не продаваме Личната информация на нашите потребители.</p>
  </div>
</template>

<script>
export default {
  name: "BgPrivacyPolicy"
}
</script>