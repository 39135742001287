<template functional>
  <div>
    <h1>Отказ от отговорност</h1>
    <p>Последна промяна 2020-10-24</p>

    <p>PriceX.app ви предоставя достъп до https://pricex.app („Уебсайтът“) и ви кани да закупите
      услуги, предлагани тук.</p>

    <h1>Определения и ключови термини</h1>
    <p>За да ви помогнем да разберете нещата възможно най-ясно в тази Политика за поверителност, всеки път, когато някой от тези условия са
      посочени, са строго дефинирани като:</p>
    <ul>
      <li>„Бисквитка“: малко количество данни, генерирани от уебсайт и запазени от вашия уеб браузър. Използва се, за да
        идентифицираме браузъра ви, осигурим анализ, запомняме информация за вас, като вашия език и
        информация за предпочитания и/или вход.
      </li>
      <li>Фирма: когато тази политика споменава „Компания“, „ние“, „ние“ или „нашата“, това се отнася до PriceX.app, който е
        отговорен за вашата информация съгласно настоящите Общи условия.
      </li>
      <li>Услуга: отнася се до услугата, предоставяна от PriceX.app, както е описано в относителните условия (ако е налично)
        и на тази платформа.
      </li>
      <li>Уебсайт: PriceX.app е сайт, който може да бъде достъпен чрез този URL: https://pricex.app</li>
      <li>Вие: физическо или юридическо лице, което е регистрирано в PriceX.app, за да използва Услугите.</li>
    </ul>

    <h1>Ограничена отговорност</h1>
    <p>PriceX.app се стреми да актуализира и / или допълва съдържанието на уебсайта редовно. Въпреки нашите грижи
      и внимание, съдържанието може да е непълно и / или неправилно.</p>
    <p>Материалите, предлагани на уебсайта, се предлагат без никаква форма на гаранция или претенция за тяхната коректност.
      Тези материали могат да бъдат променени по всяко време без предварително известие от PriceX.app.</p>
    <p>По-специално, всички цени на уебсайта са посочени при възможност от грешки при въвеждане и/или програмиране. Ограничена отговорност
      предполага последици от такива грешки. Въз основа на такива грешки не се сключва споразумение.</p>
    <p>PriceX.app не носи отговорност за хипервръзки към уебсайтове или услуги на трети страни, включени в
      уебсайта. От нашия уебсайт можете да посещавате други уебсайтове, като следвате хипервръзки към такива външни сайтове. Докато ние
      се стремим да предоставяме само качествени връзки към полезни и етични уебсайтове, ние нямаме контрол върху съдържанието и
      естеството на тези сайтове. Тези връзки към други уебсайтове не означават препоръка за цялото съдържание, намерено в
      тези сайтове. Собствениците на сайтове и съдържанието могат да се променят без предизвестие, преди да имаме възможност
      да премахнем връзка, която може да се е объркала.</p>
    <p>Моля, имайте предвид също, че когато напуснете нашия уебсайт, други сайтове могат да имат различни политики и условия за поверителност
      които са извън нашия контрол. Моля, проверете правилата за поверителност на тези сайтове, както и техните
      „Условия за ползване“ преди да се ангажирате с какъвто и да е бизнес или да качите каквато и да е информация.</p>
    <h1>Връзки към други уебсайтове</h1>
    <p>Тази Отказ от отговорност се отнася само за Услугите. Услугите могат да съдържат връзки към други уебсайтове, които не се експлоатират или
      контролират от PriceX.app. Ние не носим отговорност за съдържанието, точността или мненията, изразени в такива
      уебсайтове и такива уебсайтове не се разследват, наблюдават или проверяват за точност или пълнота от нас.
      Моля, не забравяйте, че когато използвате връзка за преминаване от Услугите към друг уебсайт, нашата Политика за поверителност вече не е в сила.
      Вашето сърфиране и взаимодействие на всеки друг уебсайт, включително тези, които имат връзка към нашия
      платформа, е предмет на собствените правила и политики на този уебсайт. Такива трети страни могат да използват свои собствени бисквитки или
      други методи за събиране на информация за вас. Ако кликнете върху връзка на трета страна, ще бъдете насочени към нея.
      Силно Ви съветваме да прегледате Политиката за поверителност и Условията на всеки сайт, който посещавате.</p>
    <p>Ние нямаме контрол и не поемаме отговорност за съдържанието, политиките за поверителност или практиките на сайтове или услуги на трети лица.</p>
    <h1>Отказ от отговорност за грешки и пропуски</h1>
    <p>PriceX.app не носи отговорност за съдържание, код или друга неточност.</p>
    <p>PriceX.app не предоставя гаранции</p>
    <p>В никакъв случай PriceX.app не носи отговорност за някакви специални, преки, косвени, последващи или случайни щети или
      каквито и да е щети, независимо дали в резултат на договор, небрежност или друго непозволено увреждане, произтичащи от или в
      връзка с използването на Услугата или съдържанието на Услугата. PriceX.app си запазва правото да прави
      допълнения, изтривания или модификации на съдържанието на Услугата по всяко време без предварително уведомление.</p>

    <h1>Общи условия</h1>
    <p>Услугата PriceX.app и нейното съдържание се предоставят "както е" и "когато е на разположение" без никаква гаранция или
      представяния от всякакъв вид, независимо дали са изрични или подразбиращи се. PriceX.app е дистрибутор, а не издател на
      съдържание, доставено от трети страни; като такъв PriceX.app не упражнява редакционен контрол върху такова съдържание и
      не дава гаранция или представяне по отношение на точността, надеждността или валутата на каквато и да е информация, съдържание,
      услуга или стоки, предоставени чрез или достъпни чрез услугата PriceX.app.
      Без да ограничава гореизложеното, PriceX.app изрично отказва всички гаранции и изявления за
      съдържание, предадено на или във връзка с услугата PriceX.app или на сайтове, които могат да се появят като връзки в
      Услугата PriceX.app или в продуктите, предоставени като част от или по друг начин във връзка с PriceX.app
      Услуга, включително без ограничение каквито и да било гаранции за продаваемост, годност за определена цел или
      ненарушаване на правата на трети страни. Няма устни съвети или писмена информация от PriceX.app или която и да е от тях
      филиали, служители, служители, директори, агенти или други подобни ще създадат гаранция. Цена и наличност
      информацията подлежи на промяна без предизвестие. Без да ограничава гореизложеното, PriceX.app не гарантира
      че услугата PriceX.app ще бъде непрекъсната, неповредена, навременна или без грешки.</p>
    <h1>Отказ от авторско право</h1>
    <p>Всички права на интелектуална собственост по отношение на тези материали са предоставени на PriceX.app. Копиране, разпространение и
      всяко друго използване на тези материали не е разрешено без писменото разрешение на PriceX.app, освен и само
      доколкото в предписанията на задължителното право е предвидено друго (като правото на цитиране), освен ако не е предвидено друго
      посочени за определени материали.</p>
    <h1>Разкриване на партньорски връзки</h1>
    <p>PriceX.app има партньорски връзки и в този раздел на отказа от отговорност ще разгледаме начина, по който използваме тези партньорски
      връзки от други уебсайтове / компании и продукти. Тези „партньорски връзки“ са специфични URL адреси, които съдържат
      идентификационния номер на потребителя или потребителското име.</p>
    <p>В съответствие с указанията на FTC, моля, вземете следното за връзките и публикациите на този сайт:</p>
    <ul>
      <li>Всяка / всички връзки в PriceX.app са партньорски връзки, от които получаваме малка комисионна от продажби на
        определени артикули, но цената е еднаква за вас. С нарастването на PriceX.app нарастват и разходите, свързани с
        управлението и поддържането му, както и партньорските връзки са начин, по който помагаме да компенсираме тези разходи.
      </li>
    </ul>
    <p>Може да участваме в партньорски програми като:</p>

    <ul>
      <li>Profitshare</li>
      <li>Други</li>
    </ul>
    <h1>Правно информирани</h1>
    <p>Материалите, коментарите или информацията, предоставени от PriceX.app, са само за образователни цели и не следва да се считат за правни, счетоводни или данъчни съвети.
      Вие носите отговорност за какво
      може да се свърши с информацията, която получавате от PriceX.app. Като такива, като посетите и използвате този уебсайт
      признавате и се съгласете, че сте били уведомени от квалифициран правен експерт (т.е. вашият адвокат), се е съгласил
      да участвате в каквато и да е законна дейност. Моля, свържете се със собствения си адвокат, счетоводител или данъчен специалист
      по повод конкретни въпроси, свързани с предоставената информация, които са правни, счетоводни или данъчни.</p>
    <h1>Финансово информирани</h1>
    <p>Този документ съдържа прогнозни изявления, които включват рискове и несигурности, както и предположения
      че, ако някога се материализират или се окажат неверни, може да доведе до или резултатите да се различават съществено от тези
      изразени или подразбиращи се от прогнозните твърдения и предположения.
      Тези рискове и несигурности включват, но не се ограничават до, риск от икономическо забавяне, риск от над
      или недостатъчно закупуване, рискът потребителите да не пазаруват онлайн на нашия уебсайт със скоростта, която очакваме, рискът
      недостиг на доставчици, риск от нова или нарастваща конкуренция, риск от природно или някакъв друг вид бедствие
      влияещи върху изпълнението ни на исторически операции или уеб сървъри, както и на риска от природни бедствия.
      Всички твърдения, различни от исторически факти, са изявления, които могат да се считат за бъдещи
      изявления, включително изявления за очакване или вярване; и всяко изявление за предположения, залегнали в основата на някое от
      гореизложеното. PriceX.app не поема никакво задължение.</p>
    <h1>Образователно информирани</h1>
    <p>Всяка информация, предоставена от PriceX.app, е само за образователни цели и не трябва да се тълкува като a
      препоръка за конкретен план за лечение, продукт или начин на действие. PriceX.app е дистрибутор, но не
      издател на съдържанието, предоставено от трети страни; като такъв PriceX.app не упражнява никакъв редакционен контрол върху
      такова съдържание и не дава гаранция или представяне по отношение на точността, надеждността или валутата на което и да е от тях
      информация или образователно съдържание, предоставено чрез или достъпно чрез PriceX.app.
      Без да ограничава гореизложеното, PriceX.app изрично отказва всички гаранции и изявления във всяко
      съдържание, предадено на или във връзка с PriceX.app или на сайтове, които могат да се появят като връзки на PriceX.app, или
      в продуктите, предоставени като част от или по друг начин във връзка с приложението PriceX. Без устен съвет или
      писмена информация, предоставена от PriceX.app или някой от нейните филиали, служители, служители, директори, агенти или
      подобно ще създаде гаранция.</p>
    <h1>Рекламно информирани</h1>
    <p>Този уебсайт може да съдържа реклами на трети страни и връзки към сайтове на трети страни. PriceX.app не дава никакви гаранции
      относно точността или годността на която и да е информация, съдържаща се в тези реклами или
      сайтове и не поема никаква отговорност за поведението или съдържанието на тези реклами и
      сайтове и предложенията, направени от трети страни.</p>
    <p>Рекламата поддържа PriceX.app и много от уебсайтовете и услугите, които използвате безплатно. Работим усилено, за да се
      уверим, че рекламите са безопасни, ненатрапчиви и възможно най-подходящи.</p>
    <p>Реклами от трети страни и връзки към други сайтове, където се рекламират стоки или услуги, не са препоръки
      или препоръки от PriceX.app на сайтовете, стоките или услугите на трети страни. PriceX.app не поема отговорност
      за съдържанието на някоя от рекламите, направените обещания или качеството / надеждността на предлаганите продукти или услуги
      във всички реклами.</p>
    <h1>Информация за мненията</h1>
    <p>Всички препоръки, предоставени на тази платформа, са мнения на тези, които ги предоставят. На информацията, предоставена в
      препоръки не трябва да се разчита, за да се предскажат резултати във вашата конкретна ситуация. Резултатите, които очаквате
      ще зависи от много фактори, включително, но не само, от вашето ниво на лична отговорност,
      ангажираност и способности, в допълнение към онези фактори, които вие и / или PriceX.app може да не успеете
      предвидите.</p>
    <p>Ще дадем честни препоръки на нашите посетители, независимо от отстъпката. Всеки продукт или услуга, които тестваме
      са индивидуални преживявания, отразяващи опит от реалния живот. Препоръките могат да се показват на аудио, текст
      или видео и не са непременно представителни за всички, които ще използват нашите продукти и / или услуги.</p>
    <p>PriceX.app не гарантира същите резултати като препоръките, дадени на нашата платформа. Представени препоръки
      на PriceX.app са приложими за лицата, които ги пишат, и може да не са показателни за бъдещия успех на някой
      други лица.</p>
    <p>Моля, не се колебайте да се свържете с нас, ако искате да научите повече за препоръки, отстъпки или някое от
      продукти / услуги, които преглеждаме.</p>
    <h1>Вашето съгласие</h1>
    <p>Актуализирахме нашата Отказ от отговорност, за да ви предоставим пълна прозрачност на това, което се задава, когато посещавате нашия
      сайта и как се използва. С използването на нашия уебсайт, регистрация на акаунт или извършване на покупка вие се съгласявате с това
      съгласие с нашата Отказ от отговорност и съгласие с нейните условия.</p>

    <h1>Промени в нашата отказ от отговорност</h1>
    <p>В случай, че трябва да се актуализира, изменя или да направите всички промени в този документ, така че те да отразяват точно нашата услуга и
      политики ще го направим тук. Освен ако законът не изисква друго, тези промени ще бъдат видно публикувани тук. След това, ако продължите
      да използвате Услугата, ще бъдете обвързани от актуализираната Отказ от отговорност. Ако не искате да се съгласите с това или с друго
      актуализиран Отказ от отговорност, можете да изтриете акаунта си.</p>
  </div>
</template>

<script>
export default {
  name: "BgDisclaimer"
}
</script>