<template functional>
  <div>
    <h1>Условия за ползване</h1>
    <p>Последна промяна на 2020-10-24</p>

    <h1>Общи условия</h1>
    <p>Чрез достъпване на PriceX.app, потвърждавате, че сте съгласни и обвързани с
      условията на услугата, съдържащи се в Общите условия, посочени по-долу. Тези условия важат за целият
      уебсайт и всеки имейл или друг вид комуникация между вас и PriceX.app.</p>
    <p>При никакви обстоятелства екипът на PriceX.app не носи отговорност за преки, косвени, специални, случайни или
      последващи щети, включително, но не само, загуба на данни или печалба, произтичащи от употребата или
      невъзможност за използване, материалите на този сайт, дори ако екипът на PriceX.app или упълномощен представител
      е уведомен за възможността за такива щети. Ако използването на материали от този сайт доведе до необходимост
      за обслужване, ремонт или корекция на оборудване или данни, вие поемате всички разходи за това.</p>
    <p>PriceX.app не носи отговорност за какъвто и да е резултат, който може да възникне по време на използването на нашия
      ресурси. Ние си запазваме правата за промяна на цените и ревизиране на политиката за използване на ресурсите във всеки един момент.</p>

    <h1>Разрешително</h1>
    <p>PriceX.app ви предоставя отменяем, неизключителен, непрехвърлим, ограничен лиценз за изтегляне, инсталиране и
      използвайте уебсайта стриктно в съответствие с условията на това споразумение.</p>
    <p>Тези Общи условия са договор между вас и PriceX.app (посочени в тези Общи условия
      като "PriceX.app", "нас", "ние" или "нашият"), доставчикът на уебсайта PriceX.app и достъпните услуги
      от уебсайта PriceX.app (които в настоящите Общи условия се наричат общо като
      "Услуга PriceX.app").</p>
    <p>Вие се съгласявате да бъдете обвързани от настоящите Общи условия. Ако не сте съгласни с настоящите Общи условия,
      моля, не използвайте услугата PriceX.app. В тези Общи условия "вие" се отнася и за вас като за
      физическо лице и на субекта, който представлявате. Ако нарушите някое от тези Общи условия, ние си запазваме
      право да анулирате акаунта ви или да блокирате достъпа до него без предизвестие.</p>

    <h1>Определения и ключови термини</h1>
    <p>За да ви помогнем да разберете нещата възможно най-ясно в тези Общи условия, всеки път, когато някой от тези условия е
      посочени, са строго дефинирани като:</p>
    <ul>
      <li>„Бисквитка“: малко количество данни, генерирани от уебсайт и запазени от вашия уеб браузър. Използва се, за да
        идентифицираме браузъра ви, осигурим анализ, запомняме информация за вас, като вашия език и
        информация за предпочитания и/или вход.
      </li>
      <li>Фирма: когато тази политика споменава „Компания“, „ние“, „ние“ или „нашата“, това се отнася до PriceX.app, който е
        отговорен за вашата информация съгласно настоящите Общи условия.
      </li>
      <li>Държава: където са базирани PriceX.app или собствениците / основателите на PriceX.app, в този случай е България</li>
      <li>Устройство: всяко свързано с интернет устройство, като телефон, таблет, компютър или друго устройство, което може да бъде
        използвани за посещение на PriceX.app и използване на услугите.
      </li>
      <li>Услуга: отнася се до услугата, предоставяна от PriceX.app, както е описано в относителните условия (ако е налично)
        и на тази платформа.
      </li>
      <li>Услуга на трета страна: отнася се до рекламодатели, спонсори на конкурси, промоционални и маркетингови партньори и
        други, които предоставят нашето съдържание или чиито продукти или услуги смятаме, че могат да ви заинтересуват.
      </li>
      <li>Уебсайт: PriceX.app е сайт, който може да бъде достъпен чрез този URL: https://pricex.app</li>
      <li>Вие: физическо или юридическо лице, което е регистрирано в PriceX.app, за да използва Услугите.</li>
    </ul>

    <h1>Ограничения</h1>
    <p>Вие се съгласявате да не го правите и няма да позволите на другите да:</p>
    <ul>
      <li>Модифицирате, правите производни произведения, разглобявате, дешифрирате или реверсирате някоя част от
        уебсайтът.
      </li>
      <li>Премахвате, променяте или скривате всяко собственическо известие (включително всяко известие за авторски права или търговска марка) на
        PriceX.app или неговите филиали, партньори, доставчици или лицензодатели на уебсайта.
      </li>
    </ul>
    <h1>Политика за връщане и възстановяване</h1>
    <p>Благодаря че пазарувате в PriceX.app. Оценяваме факта, че харесвате нашите услуги, които изграждаме. Ние също
      искаме да сте сигурни, че имате възнаграждаващо изживяване, докато проучвате, оценявате и купувате нашите
      продукти и услуги.</p>
    <p>Както при всяко изживяване при пазаруване, и тук има условия, които се прилагат за транзакции в PriceX.app.
      Ще бъдем толкова кратки, колкото позволяват нашите адвокати. Основното нещо, което трябва да запомните е, че като направите поръчка или
      правейки покупка в PriceX.app, вие се съгласявате с условията заедно с Политиката за поверителност на PriceX.app.</p>
    <p>Ако по някаква причина не сте напълно доволни от каквито и да е стоки или услуги, които предоставяме, не се колебайте
      да се свържете с нас и ние ще обсъдим всеки от проблемите, които преживявате с нашия продукт.</p>

    <h1>Вашите предложения</h1>
    <p>Всякакви отзиви, коментари, идеи, подобрения или предложения (заедно „Предложения“), предоставени от вас на
      PriceX.app по отношение на уебсайта остава единствената и изключителна собственост на PriceX.app.</p>
    <p>PriceX.app ще бъде свободен да използва, копира, модифицира, публикува или разпространява предложенията за всякакви цели и
      по какъвто и да е начин без никакъв кредит или каквато и да е компенсация за вас.</p>

    <h1>Вашето съгласие</h1>
    <p>Актуализирахме нашите Общи условия, за да ви предоставим пълна прозрачност на това, с което се съгласявате когато
      посещавате нашия сайт. Като използвате нашия уебсайт, регистрирате акаунт или създадете
      покупка, вие се съгласявате с нашите Общи условия.</p>

    <h1>Връзки към други уебсайтове</h1>
    <p>Настоящите Общи условия се отнасят само за Услугите. Услугите могат да съдържат връзки към други уебсайтове, които не са
      управлявани или контролирани от PriceX.app. Ние не носим отговорност за съдържанието, точността или изразените мнения
      в такива уебсайтове и такива уебсайтове не се разследват, наблюдават или проверяват за точност или пълнота
      от нас. Моля, не забравяйте, че когато използвате връзка за преминаване от Услугите към друг уебсайт, нашите Условия за използване вече не са в сила. Вашето сърфиране и взаимодействие на всеки друг уебсайт, включително тези, които
      имат връзка към нашата платформа, е предмет на собствените правила и политики на този уебсайт. Такива трети страни могат да използват
      собствени бисквитки или други методи за събиране на информация за вас.</p>

    <h1>Бисквитки</h1>
    <p>PriceX.app използва „бисквитки“, за да идентифицира областите от нашия уебсайт, които сте посетили. Бисквитката е малко
      парче данни, съхранявани на вашия компютър или мобилно устройство от вашия уеб браузър. Ние използваме бисквитки, за да подобрим
      ефективността и функционалността на нашия уебсайт, но не са от съществено значение за тяхното използване. Без тях обаче,
      определена функционалност като видеоклипове може да стане недостъпна или ще се наложи да въведете своите
      данни за вход всеки път, когато посещавате уебсайта, тъй като не бихме могли да запомним, че сте влезли
      преди това. Повечето уеб браузъри могат да бъдат настроени да деактивират използването на бисквитки. Ако обаче деактивирате бисквитките, вие
      може да нямате достъп до функционалността на нашия уебсайт правилно или изобщо. Никога не поставяме лична
      Информация за идентифициране в „бисквитките“.</p>

    <h1>Промени в нашите Общи условия</h1>
    <p>Вие признавате и се съгласявате, че PriceX.app може да спре (постоянно или временно) да предоставя Услугата
      (или каквито и да е функции в рамките на Услугата) на вас или на потребителите като цяло по преценка на PriceX.app,
      без предизвестие за вас. Можете да спрете да използвате Услугата по всяко време. Не е нужно специално да
      информирате PriceX.app, когато спрете да използвате Услугата. Вие признавате и се съгласявате с това,
      че ако PriceX.app деактивира достъпа до вашия акаунт, може да ви бъде възпрепятстван достъп до Услугата, вашите
      данни за акаунта или всякакви файлове или други материали, които се съдържат във вашия акаунт.</p>
    <p>Ако решим да променим нашите Общи условия, ще публикуваме тези промени на тази страница и/или ще актуализираме
      Дата на изменение на Общите условия по-горе.</p>

    <h1>Промени на нашия уебсайт</h1>
    <p>PriceX.app си запазва правото да модифицира, преустановява или прекратява, временно или окончателно, уебсайта
      или всяка услуга, към която се свързва, със или без предизвестие и без отговорност към вас.</p>

    <h1>Актуализации на нашия уебсайт</h1>
    <p>PriceX.app може от време на време да предоставя подобрения или подобрения на функциите/функционалността на
      уебсайта, които могат да включва корекции, корекции на грешки, актуализации, надстройки и други модификации („Актуализации“).</p>
    <p>Актуализациите могат да модифицират или изтрият определени функции и/или функционалности на уебсайта. Вие се съгласявате, че
      PriceX.app няма задължение (i) да предоставя каквито и да е Актуализации или (ii) да продължава да предоставя или разрешава такива
      конкретни функции и/или функционалности на уебсайта за вас.</p>
    <p>Освен това се съгласявате, че всички Актуализации (i) ще се считат за неразделна част от уебсайта и (ii)
      при спазване на условията на настоящото споразумение.</p>

    <h1>Услуги на трети страни</h1>
    <p>Можем да показваме, включваме или правим достъпно съдържание от трети страни (включително данни, информация, приложения и
      други продукти) или предоставяме връзки към уебсайтове или услуги на трети страни („Услуги на трети страни“).</p>
    <p>Вие признавате и се съгласявате, че PriceX.app не носи отговорност за услуги на трети страни, включително
      тяхната точност, пълнота, навременност, валидност, спазване на авторските права, законност, благоприличие, качество или други
      в този аспект. PriceX.app не поема и не носи никаква отговорност или отговорност към вас
      или друго физическо или юридическо лице за услуги на трети страни.</p>
    <p>Услугите на трети страни и връзките към тях се предоставят единствено като удобство за вас и вие имате достъп и употребявайки
      ги е изцяло на ваш риск и при спазване на условията на тези трети страни.</p>

    <h1>Срок и прекратяване</h1>
    <p>Настоящото споразумение остава в сила, докато не бъде прекратено от вас или PriceX.app.</p>
    <p>PriceX.app може, по свое усмотрение, по всяко време и по каквато и да е причина, да преустанови или прекрати това
      Споразумение със или без предварително уведомление.</p>
    <p>Настоящото споразумение ще бъде прекратено незабавно, без предварително известие от PriceX.app, в случай че не успеете
      да се съобрази с която и да е разпоредба от настоящото споразумение. Можете също да прекратите настоящото споразумение, като изтриете
      уебсайта и всички негови копия от вашия компютър.</p>
    <p>При прекратяване на настоящото споразумение вие ще трябва да прекратите всякакво използване на уебсайта и ще трябва да изтриете всички копия на
      уебсайта от вашия компютър.</p>
    <p>Прекратяването на настоящото споразумение няма да ограничи нито едно от правата или средствата за правна защита на PriceX.app по закон в
      случай на нарушение от ваша страна (по време на срока на настоящото споразумение) на някое от вашите задължения по настоящето
      Споразумение.</p>

    <h1>Известие за нарушаване на авторски права</h1>
    <p>Ако сте собственик на авторски права или агент на такъв собственик и смятате, че който и да е материал на нашия уебсайт представлява
      нарушение на авторските Ви права, моля свържете се с нас, като посочите следната информация: (а) физическа или
      електронен подпис на притежателя на авторските права или на лице, упълномощено да действа от негово име; б) идентификация
      на материала, за който се твърди, че нарушава; (в) вашата информация за контакт, включително вашия адрес,
      телефонен номер и имейл; (г) изявление от ваша страна, че имате добросъвестно убеждение, че използването на
      материалът не е упълномощен от собствениците на авторските права; и (д) декларация, че информацията в
      известието е точно и под наказание за лъжесвидетелстване сте упълномощени да действате от името на
      собственика.</p>

    <h1>Обезщетение</h1>
    <p>Вие се съгласявате да обезщетите и държите PriceX.app и неговите дъщерни дружества, филиали, собственици, служители,
      агенти, партньори и лицензодатели (ако има такива) невредими от всякакви претенции или искания, включително разумни адвокатски
      такси, дължими или произтичащи от вашето: (а) използване на уебсайта; (б) нарушение на настоящото споразумение или който и да е закон или
      регулиране; или (в) нарушение на което и да е право на трета страна.</p>

    <h1>Без гаранции</h1>
    <p>Уебсайтът ви се предоставя "КАКТО Е" и "КОГАТО Е НАЛИЧЕН" и с всички неизправности и дефекти без гаранция за
      всякакъв вид. В максималната степен, разрешена от приложимото законодателство, PriceX.app, от свое име и името
      на своите филиали и неговите и техните съответни лицензодатели и доставчици на услуги, изрично отказва всички
      гаранции, независимо дали са изрични, подразбиращи се, законови или по друг начин, по отношение на уебсайта, включително всички
      подразбиращи се гаранции за продаваемост, годност за определена цел, заглавие и ненарушаване и
      гаранции, които могат да възникнат в резултат на сделката, хода на изпълнението, използването или търговската практика. Без
      ограничение до гореизложеното, PriceX.app не предоставя никакви гаранции или ангажименти и не дава представа за
      всякакъв вид, че уебсайтът ще отговаря на вашите изисквания, ще постигне всички предвидени резултати, ще бъде съвместим или работи
      с който и да е друг софтуер, системи или услуги, работи без прекъсване, отговаря за каквито и да е резултати или
      стандарти за надеждност или без грешки или че всякакъв вид грешки или дефекти могат или ще бъдат коригирани.</p>
    <p>Без да ограничаваме гореизложеното, нито PriceX.app, нито който и да е доставчик на PriceX.app дава
      гаранция от всякакъв вид, изрична или подразбираща се: (i) по отношение на работата или наличността на уебсайта, или
      информация, съдържание и материали или продукти, включени в тях; (ii) че уебсайтът ще бъде
      непрекъснато или без грешки; (iii) по отношение на точността, надеждността или валутата на каквато и да е информация или
      съдържание, предоставено чрез уебсайта; или (iv) че уебсайтът, неговите сървъри, съдържанието или изпратените имейли
      от или от името на PriceX.app не съдържа вируси, скриптове, троянски коне, червеи, злонамерен софтуер, бомби или
      други вредни компоненти.</p>
    <p>Някои юрисдикции не позволяват изключването или ограниченията на подразбиращите се гаранции или ограниченията върху
      приложимите законови права на потребителя, така че някои или всички по-горе изключения и ограничения могат
      не се отнася за вас.</p>

    <h1>Ограничаване на отговорността</h1>
    <p>Независимо от каквито и да е щети, които бихте могли да понесете, цялата отговорност на PriceX.app и някои от неговите доставчици
      съгласно която и да е разпоредба от настоящото споразумение и вашето изключително средство за защита за всичко гореизложено ще бъде ограничено до
      действително платената от вас сума в уебсайта.</p>
    <p>В максималната степен, разрешена от приложимото законодателство, в никакъв случай PriceX.app или неговите доставчици не носят отговорност
      за всякакви специални, случайни, косвени или последващи щети (включително, но не само,
      щети за загуба на печалба, за загуба на данни или друга информация, за прекъсване на бизнеса, за лични
      нараняване, поради загуба на неприкосновеност на личния живот, произтичаща от или по какъвто и да е начин свързана с използването или невъзможността за използване на
      уебсайт, софтуер на трети страни и/или хардуер на трети страни, използван с уебсайта или по друг начин във връзка
      с която и да е разпоредба от настоящото споразумение), дори ако PriceX.app или друг доставчик е бил уведомен за
      възможността за такива щети и дори ако средство за защита се провали от основната си цел.</p>
    <p>Някои държави/юрисдикции не позволяват изключването или ограничаването на случайни или последващи щети, така че
      горното ограничение или изключване може да не се отнася за вас.</p>

    <h1>Делимост</h1>
    <p>Ако някоя от разпоредбите на настоящото споразумение се счита за неприложима или невалидна, тази разпоредба ще бъде променена и
      тълкувана за постигане на целите си във възможно най-голяма степен при прилагане на
      закона и останалите разпоредби ще продължат с пълна сила и ефект.</p>
    <p>Настоящото споразумение, заедно с Политиката за поверителност и всички други правни съобщения, публикувани от PriceX.app в
      Услуги, представлява цялостното споразумение между Вас и PriceX.app относно Услугите. Ако някоя
      Разпоредба на настоящото споразумение се счита за невалидна от компетентен съд, недействителността на такава
      разпоредбата не засяга валидността на останалите разпоредби на настоящото споразумение, които остават в
      пълна сила и ефект.</p>

    <h1>Отказ</h1>
    <p>С изключение на предвиденото в настоящото, неизпълнението на право или изискването за изпълнение на задължение по
      настоящото споразумение не засяга способността на дадена страна да упражнява това право или да изисква такова изпълнение по какъвто и да е начин
      времето след това, нито отказът от нарушение не представлява отказ от всяко последващо нарушение.</p>
    <p>При неизпълнение и без забавяне при упражняване от страна на която и да е от страните, каквото и да е право или правомощия съгласно
      настоящото споразумение действа като отказ от това право или правомощие. Нито едно или частично упражнение на
      всяко право или правомощие съгласно настоящото споразумение възпрепятства по-нататъшното упражняване на това или всяко друго право, предоставено тук.
      В случай на конфликт между настоящото споразумение и всяка приложима покупка или други условия, условията на
      това споразумение се урежда.</p>

    <h1>Изменения на настоящото споразумение</h1>
    <p>PriceX.app си запазва правото, по свое усмотрение, да модифицира или замени настоящото споразумение по всяко време. Ако
      преразглеждането е съществено, ще предоставим предизвестие от поне 30 дни преди влизането в сила на новите условия. Какво
      представлява съществена промяна, ще бъде определена по наше собствено усмотрение.</p>
    <p>Продължавайки да осъществявате достъп или да използвате нашия уебсайт след влизането в сила на каквито и да било ревизии, вие се съгласявате да бъдете обвързани от
      ревизираните условия. Ако не сте съгласни с новите условия, вече нямате право да използвате PriceX.app.</p>

    <h1>Цялостно споразумение</h1>
    <p>Споразумението представлява цялото споразумение между вас и PriceX.app относно използването от вас на уебсайта
      и замества всички предишни и едновременни писмени или устни споразумения между вас и PriceX.app.</p>
    <p>Може да подлежите на допълнителни условия, които се прилагат, когато използвате или закупите други PriceX.app
      услуги, които PriceX.app ще ви предостави по време на такова използване или покупка.</p>

    <h1>Актуализации на нашите условия</h1>
    <p>Може да променим нашата Услуга и политики и може да се наложи да направим промени в настоящите Условия, така че те
      точно да отразяват нашата услуга и политики. Освен ако законът не изисква друго, ние ще Ви уведомим (
      например чрез нашата услуга), преди да направим промени в тези Условия и да ви дадем възможност за преглед
      преди да влязат в сила. След това, ако продължите да използвате Услугата, ще бъдете обвързани от актуализираните
      Условия. Ако не искате да се съгласите с тези или някои актуализирани Условия, можете да изтриете акаунта си.</p>

    <h1>Интелектуална собственост</h1>
    <p>Уебсайтът и цялото му съдържание, функции и функционалност (включително, но не само всички
      информация, софтуер, текст, дисплеи, изображения, видео и аудио, както и дизайн, подбор и подреждане
      от тях), са собственост на PriceX.app, неговите лицензодатели или други доставчици на такъв материал и са защитени от българските
      и международни авторски права, търговска марка, патент, търговска тайна и друга интелектуална собственост или собственост
      закони за правата. Материалът не може да бъде копиран, модифициран, възпроизвеждан, изтеглян или разпространяван по какъвто и да е начин, в
      изцяло или частично, без изричното предварително писмено разрешение на PriceX.app, освен както е
      изрично предоставени в тези Общи условия. Всяко неразрешено използване на материала е забранено.</p>

    <h1>Споразумение за арбитраж</h1>
    <p>Този раздел се прилага за всеки спор, и не включва спор, свързан с ПРЕТЕНЦИИ искания за налагане ИЛИ
      Справедливо обезщетение във връзка с прилагането или действителността на PriceX.app или ваша интелектуална собственост.
      Терминът „спор“ означава всеки спор, действие или друг спор между вас и PriceX.app
      относно Услугите или това споразумение, независимо дали в договор, гаранция, непозволено увреждане, устав, регламент,
      наредба или друга правна или справедлива основа. „Спор“ ще получи най-широкия възможен смисъл
      допустими по закон.</p>

    <h1>Известие за спор</h1>
    <p>В случай на спор, вие или PriceX.app трябва да изпратите на другия Известие за оспорване, което е писмено
      декларация, която посочва името, адреса и информацията за контакт на страната, която го дава, фактите
      пораждащ спора и поисканото облекчение. Трябва да изпратите всяко Известие за оспорване по имейл на: <a
          href="mailto:contacts@pricex.app">contacts@pricex.app</a> PriceX.app ще
      да Ви изпрати Известие за оспорване по пощата на Вашия адрес, ако го имаме, или по друг начин на Вашия имейл адрес.
      Вие и PriceX.app ще се опитате да разрешите всеки спор чрез неофициални преговори в рамките на шестдесет (60) дни
      от датата на изпращане на известието за оспорване. След шестдесет (60) дни вие или PriceX.app може да започнете
      арбитраж.</p>

    <h1>Обвързващ арбитраж</h1>
    <p>Ако вие и PriceX.app не разрешите спорове чрез неофициални преговори, други усилия за разрешаване на
      спорът ще се води изключително чрез обвързващ арбитраж, както е описано в този раздел. Вие се отказвате от
      правото да водите съдебни спорове (или да участвате като страна) за всички спорове в съда пред съдия или
      жури. Спорът се решава чрез обвързващ арбитраж в съответствие с търговския арбитраж. Всяка от страните може да търси временна или предварителна заповед
      освобождаване от всеки съд с компетентна юрисдикция, както е необходимо за защита на правата или собствеността на страната
      до приключване на арбитраж. Всички правни, счетоводни и други разходи, такси и разноски
      направени от преобладаващата страна, се поемат от непреобладаващата страна.</p>

    <h1>Изложения и поверителност</h1>
    <p>В случай че изпратите или публикувате някакви идеи, творчески предложения, дизайни, снимки, информация,
      реклами, данни или предложения, включително идеи за нови или подобрени продукти, услуги, функции,
      технологии или промоции, вие изрично се съгласявате, че такива подавания автоматично ще бъдат третирани като
      неповерителни и непатентовани и ще станат единствена собственост на PriceX.app без никакви
      обезщетение или кредит за вас. PriceX.app и свързаните с него лица няма да имат задължения
      по отношение на такива подания или публикации и може да използва идеите, съдържащи се в тези становища или публикации, за всички
      цели във всякаква среда за постоянно, включително, но не само, разработване, производство и
      маркетинг на продукти и услуги, използващи такива идеи.</p>

    <h1>Промоции</h1>
    <p>PriceX.app може от време на време да включва състезания, промоции, лотарии или други дейности
      („Промоции“), които изискват да изпратите материали или информация, отнасяща се до себе си. Моля, обърнете внимание, че всички
      Промоциите могат да се уреждат от отделни правила, които могат да съдържат определени изисквания за допустимост, като например
      ограничения по отношение на възрастта и географското местоположение. Вие носите отговорност да прочетете всички правила за промоции
      определете дали отговаряте на условията за участие. Ако влезете в някаква промоция, вие се съгласявате да се придържате към нея
      и да спазва всички правила за промоции.</p>
    <p>Допълнителни условия могат да се прилагат за покупки на стоки или услуги в или чрез Услугите, които
      Правилата и условията са част от настоящото споразумение чрез това позоваване.</p>

    <h1>Печатни грешки</h1>
    <p>В случай, че продукт и/или услуга са посочени на неправилна цена или с невярна информация поради
      печатна грешка, ние ще имаме правото да откажем или анулираме всякакви поръчки, направени за продукта и/или
      услуга, посочена на неправилна цена. Ние ще имаме правото да откажем или да отменим всяка такава поръчка независимо дали
      не поръчката е потвърдена и кредитната ви карта е таксувана. Ако кредитната ви карта вече е таксувана
      за покупката и поръчката ви е анулирана, ние незабавно ще издадем кредит по сметката на вашата кредитна карта
      или друга платежна сметка в размер на таксата.</p>

    <h1>Други</h1>
    <p>Ако по някаква причина съд с компетентна юрисдикция намери някоя разпоредба или част от настоящите Общи условя
      за неизпълними, останалата част от настоящите Общи условия ще продължи с пълна сила и
      ефект. Всяко отказване от която и да е разпоредба от настоящите Общи условия ще влезе в сила само ако е в писмена форма и
      подписан от упълномощен представител на PriceX.app. PriceX.app ще има право на заповед или друго
      справедливо облекчение (без задълженията за осчетоводяване на облигации или поръчителство) в случай на нарушение или
      изпреварващо нарушение от вас. PriceX Ltd. оперира и контролира услугата PriceX.app от своите офиси в България.
      Услугата не е предназначена за разпространение или използване от което и да е физическо или юридическо лице в която и да е юрисдикция или
      държава, в която такова разпространение или използване би било в противоречие със закона или разпоредбите. Съответно тези лица
      които избират да получат достъп до услугата PriceX.app от други места, правят това по собствена инициатива и са
      единствено отговорен за спазването на местните закони, ако и доколкото са приложими местните закони. Тези
      Общите условия (които включват и Политиката за поверителност на PriceX.app) съдържат цялото
      разбирателство и замества всички предишни разбирателства между вас и PriceX.app по отношение на неговия предмет,
      и не може да бъде променен или модифициран от вас. Заглавията на разделите, използвани в настоящото споразумение, са за
      само за удобство и няма да получи легален статус.</p>

    <h1>Отказ от отговорност</h1>
    <p>PriceX.app не носи отговорност за съдържание, код или друга неточност.</p>
    <p>PriceX.app не предоставя гаранции.</p>
    <p>В никакъв случай PriceX.app не носи отговорност за някакви специални, преки, непреки, последващи или случайни
      щети или каквито и да било щети, независимо дали в резултат на договор, небрежност или друго непозволено увреждане, произтичащи от това
      на или във връзка с използването на Услугата или съдържанието на Услугата. Компанията си запазва
      право да правите допълнения, изтривания или модификации на съдържанието на Услугата по всяко време без
      предварително уведомление.</p>
    <p>Услугата PriceX.app и нейното съдържание се предоставят "както е" и "когато е на разположение" без никаква гаранция или
      представления от всякакъв вид, независимо дали са изрични или подразбиращи се. PriceX.app е дистрибутор, а не издател на
      съдържанието, предоставено от трети страни; като такъв PriceX.app не упражнява редакционен контрол върху такова съдържание
      и не дава гаранция или представяне относно точността, надеждността или валутата на каквато и да е информация,
      съдържание, услуга или стоки, предоставени чрез или достъпни чрез услугата PriceX.app. Без ограничение
      гореизложеното, PriceX.app специално отказва всички гаранции и изявления във всяко съдържание
      предадени на или във връзка с услугата PriceX.app или на сайтове, които могат да се появят като връзки в
      Услугата PriceX.app или в продуктите, предоставени като част от или по друг начин във връзка с
      Услуга PriceX.app, включваща, без ограничение, всякакви гаранции за продаваемост и годност за конкретен
      цел или ненарушаване на правата на трети страни. Няма устни съвети или писмена информация от PriceX.app
      или някой от неговите филиали, служители, служители, директори, агенти или други подобни ще създаде гаранция. Цена
      и информацията за наличност подлежи на промяна без предизвестие. Без да ограничава гореизложеното, PriceX.app
      не гарантира, че Услугата PriceX.app ще бъде непрекъсната, неповредена, навременна или без грешки.</p>
  </div>
</template>

<script>
export default {
  name: "BgTermsAndConditions",
}
</script>